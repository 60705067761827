import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { Grid, Typography, Hidden, Input, Button } from '@material-ui/core'
import { withWidth, withStyles, Paper } from '@material-ui/core'
import routesCompany from '../../company/routesCompany'
import routesThermometer from '../../thermometer/routesThermometer'
import { auth, firestore } from '../../../firebase/firebase'

const thermometerIcon = "/images/logo-termometro.png"
// Cambiar a logo con colores azules
const thermometer = "/images/letras_azules_termometro-innspiral.png"

const codeToMessage = {
  'auth/invalid-email': 'Email inválido',
  'auth/user-disabled': 'Usuario deshabilitado',
  'auth/user-not-found': 'Usuario inexistente',
  'auth/wrong-password': 'Contraseña incorrecta',
}

const StyledPaper = withStyles({
  root: {
    backgroundImage: "-webkit-linear-gradient(160deg, #FFFFFF 55%, #1B2642 45%)",
    minHeight: "580px",
    borderRadius: "13px"
  }
})(Paper)

const StyledButton = withStyles({
  root: {
    backgroundColor: "#1C89A7",
    color: "#FFFF",
    textTransform: 'none',
    '&:hover': {
      backgroundColor: "#1C89A7",
      color: '#FFFF',
      textTransform: 'none',
    }
  }
})(Button);

const styles = () => ({
  root: {
    backgroundColor: "red",
    color: "black"
  },
  formContainer: {
    marginTop: "80px"
  },
  formItem: {
    marginTop: "15px",
    fontSize: "1.313em",
    fontFamily: "Raleway",
    textTranform: "light"
  }
})

class LoginUserView extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: ""
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  login = () => {
    const { email, password, } = this.state;
    const { history } = this.props;
    auth.signInWithEmailAndPassword(email, password)
    .then(async (authUser) => {
      let userDoc = await firestore.collection('users').doc(authUser.user.uid).get()
      let user = userDoc.data()
      if(user.roles.includes('admin')){
        history.push(routesCompany.list)
      }
      // All users that are not admin should be associated with a company
      else if (user.company) {
        let companyDoc = await user.company.get()
        let company = companyDoc.data()
        history.push(routesThermometer.list.replace(':company', company.slug))
      }
    })
    .catch(error => {
      this.setState({ error: codeToMessage[error.code] })
    })
  }

  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Hidden smDown>
          <div className="body-survey">
            <StyledPaper elevation={1} >
              <Grid container spacing={40}>
                <Grid item container xs={12} md={6} alignItems="center" justify="center" direction="column">
                  <img style={{ marginTop: "75px" }} height={350} src={thermometerIcon} alt={thermometerIcon} />
                </Grid>
                <Grid item container xs={12} md={6} direction="column" alignItems="center">
                  <img width={300} style={{marginTop: "30px"}} src={thermometer} alt={thermometer} />
                  <Grid item container xs={12} md={6} direction="row" alignItems="center" justify="center" className={classes.formContainer}>
                    <Grid item container xs={12} direction="column" justify="center">
                      <Grid item xs={12}>
                        <Typography variant="h4" style={{ fontFamily: "Raleway", fontSize: "1.875em", fontWeight: "bold" }}>
                          Iniciar sesión
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5" classes={{ root: classes.formItem }}>
                          Usuario
                        </Typography>
                        <Input
                          classes={{ root: classes.formItem }}
                          className="input-form"
                          disableUnderline
                          fullWidth
                          autoFocus
                          placeholder="Usuario"
                          name="email"
                          value={this.state.name}
                          onChange={e => this.handleChange(e)}
                          type="text"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5" classes={{ root: classes.formItem }} style={{  }}>
                          Contraseña
                        </Typography>
                        <Input
                          classes={{ root: classes.formItem }}
                          disableUnderline
                          fullWidth
                          className="input-form"
                          placeholder="Contraseña"
                          name="password"
                          value={this.state.password}
                          onChange={e => this.handleChange(e)}
                          type="password"
                        />
                      </Grid>
                      <Grid item container xs={12} justify="flex-end">
                        <StyledButton
                          onClick={this.login}
                          classes={{ root: classes.formItem }}
                        >
                          Ir
                        </StyledButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </StyledPaper>
          </div>
        </Hidden>
        <Hidden mdUp>

        </Hidden>
      </React.Fragment>
    )
  }
}

LoginUserView.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
}

export default withRouter(withWidth()(withStyles(styles)(LoginUserView)))
