import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { firestore } from '../../../firebase/firebase'
import WriteAndRead from '../../general/components/WriteAndRead'

const pillars = [
  {key: 'strategyAndLeadership', title: 'Estrategia y liderazgo'},
  {key: 'innovationProcess', title: 'Proceso de innovación'},
  {key: 'organization', title: 'Organización'},
  {key: 'resources', title: 'Recursos'},
  {key: 'culture', title: 'Cultura'},
]

export default class Interviews extends React.Component {

  state = {
    strategyAndLeadership: '',
    innovationProcess: '',
    organization: '',
    resources: '',
    culture: '',
  }

  componentDidMount() {
    firestore.collection('interviews').doc(this.props.thermometerId).get()
    .then((doc) => {
      if(doc.exists){
        let interview = doc.data()
        this.setState({
          strategyAndLeadership: interview.strategyAndLeadership || 'El feedback aún no está listo, el consultor a cargo lo subirá pronto',
          innovationProcess: interview.innovationProcess || 'El feedback aún no está listo, el consultor a cargo lo subirá pronto',
          organization: interview.organization || 'El feedback aún no está listo, el consultor a cargo lo subirá pronto',
          resources: interview.resources || 'El feedback aún no está listo, el consultor a cargo lo subirá pronto',
          culture: interview.culture || 'El feedback aún no está listo, el consultor a cargo lo subirá pronto',
        })
      }
      else {
        this.setState({
          strategyAndLeadership: 'El feedback aún no está listo, el consultor a cargo lo subirá pronto',
          innovationProcess: 'El feedback aún no está listo, el consultor a cargo lo subirá pronto',
          organization: 'El feedback aún no está listo, el consultor a cargo lo subirá pronto',
          resources: 'El feedback aún no está listo, el consultor a cargo lo subirá pronto',
          culture: 'El feedback aún no está listo, el consultor a cargo lo subirá pronto',
        })
      }
    })

  }

  onTextChange = (event) => {
    this.setState({[event.target.name]: event.target.value})
  }

  sendData = (key) => {
    firestore.collection('interviews').doc(this.props.thermometerId).set({
      [key]: this.state[key],
    }, {merge: true})
    .then(() => {
      this.props.openAlert('success', 'Datos guardado con éxito')
    })
    .catch(() => {
      this.props.openAlert('error', 'Error al guardar los datos')
    })
  }

  render(){
    return(
      <Grid container className="thermometer-dashboard-container">
        <Grid item style={{width: '100%'}}>
          <h2 className="h2-admin" style={{ marginTop: '2em' }}>Resultado entrevistas medición de la innovación en {this.props.company}</h2>
          <hr style={{margin: '1em 0'}}/>
        </Grid>
        <Grid item style={{width: '100%'}}>
        {pillars.map((pillar, i) => {
          return(
            <Grid key={pillar.key} container wrap="nowrap" alignItems="baseline">
              <Grid item xs={4}>
                <h3 className="yellow-subtitle big-number" style={{textAlign: 'center'}}>{`0${i + 1}`}</h3>
              </Grid>
              <Grid item xs={8}>
                <h2 className="blue-subtitle">{pillar.title}</h2>
                <WriteAndRead value={this.state[pillar.key]} name={pillar.key} onChange={this.onTextChange} roles={this.props.authUserData.roles} onSave={() => this.sendData(pillar.key)}/>
                <hr style={{margin: '2em 0 1em'}}/>
              </Grid>
            </Grid>
          )
        })}
        </Grid>
      </Grid>
    )
  }
}

Interviews.propTypes = {
  company: PropTypes.string.isRequired,
  authUserData: PropTypes.object.isRequired,
  openAlert: PropTypes.func.isRequired,
  thermometerId: PropTypes.string.isRequired,
}
