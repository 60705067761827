import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Deadline extends Component {
  render() {
    return (
      <div className="chart" style={{ paddingBottom: '75px' }}>
        <p style={{ marginBottom: '30px', fontWeight: 'bold' }}>
          {this.props.remainingDays < 0 ?
            <span style={{color: "#fe5f66"}}>Finalizado</span>
          :
            `Quedan ${this.props.remainingDays} días más`
          }
        </p>
        <div className="progress">
          <div
            className="progress-complete"
            style={{ width: `${this.props.progress}%` }}
          />
        </div>
        <div className="time-indicators">
          <div className="item start item-left">
            {this.props.startDate}
          </div>
          {this.props.remainingDays >= 0 &&
            <div
              className="item today item-left"
              style={{ left: `${this.props.progress - 0.1}%` }}
            >
              Hoy
            </div>
          }
          <div className="item end item-right">
            {this.props.endingDate}
          </div>
        </div>
      </div>
    )
  }
}

Deadline.propTypes = {
  remainingDays: PropTypes.number,
  progress: PropTypes.number,
  startDate: PropTypes.string,
  endingDate: PropTypes.string,
}

export default Deadline
