import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link, generatePath } from 'react-router-dom'

import { DropzoneArea } from 'material-ui-dropzone'
import slugify from 'slugify'

import { Grid, Icon, Dialog, DialogActions, DialogContent, DialogTitle, Button, Input, MenuItem, Select, InputLabel, InputBase, TextField, InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { withStyles } from '@material-ui/core/styles'

import GeneralContext from '../../general/GeneralContext'
import { firestore, storage } from '../../../firebase/firebase'
import routesThermometer from '../../thermometer/routesThermometer'
import Loader from '../../general/components/Loader'

const states = [
  {value: 'all', label: 'Todos'},
  {value: 'active', label: 'Activos'},
  {value: 'notActive', label: 'Inactivos'},
]

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: '10%',
  },
  menu: {
    width: 200,
  },
})

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  input: {
    borderRadius: 12,
    position: 'relative',
    backgroundColor: '#FFFFFF',
    border: '1px solid #BBBBBB',
    fontSize: 16,
    width: 'auto',
    padding: '10px 26px 10px 15px',
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

class ListCompanyView extends Component {

  constructor(props) {
    super(props)
    this.state = {
      companies: null,
      showDialog: false,
      industries: [],
      companyId: '',
      nameCompany: '',
      industry: '',
      logoFile: null,
      filterState: 'all',
      filterIndustry: 'all',
      filterName: '',
      industriesForFilter: [],
    }
  }

  componentDidMount = async () => {
    await this.context.setContext('nameCompany', '')
    /**
     * Load companies.
     */
    let companiesSnapshot
    try {
      companiesSnapshot = await firestore.collection('companies').orderBy('name').get()
    } catch (error) {
      this.error(error)
      return
    }
    let companies
    if (companiesSnapshot.docs.length === 0) {
      companies = []
    } else {
      companies = companiesSnapshot.docs.map(doc => (
        { id: doc.id, ...doc.data()}
      ))
    }
    await this.setState({ companies })
    /**
     * Load industries.
     */
    let industriesSnapshot
    try {
      industriesSnapshot = await firestore.collection('industries').orderBy('name').get()
    } catch (error) {
      this.error(error)
      return
    }
    let industriesForFilter = industriesSnapshot.docs.map(doc => {
      let industry = doc.data()
      return {label: industry.name, value: industry.name}
    })
    industriesForFilter.unshift({label: 'Todas', value: 'all'})
    await this.setState({
      industries: industriesSnapshot.docs.map(doc => doc.data()),
      industriesForFilter: industriesForFilter
    })
  }

  showDataCompany = (companyId) => {
    this.setState({ companyId })
    const nameCompany = document
      .querySelector(`#company-${companyId} .name`)
      .innerText
    const industry = document
      .querySelector(`#company-${companyId} .industry`)
      .innerText
    this.setState({ nameCompany })
    this.setState({ industry })
  }

  handleClickOpenDialog = async (e, companyId) => {
    e.preventDefault()
    this.setState({ showDialog: true })
    this.showDataCompany(companyId)
  }

  handleCloseDialog = () => {
    this.setState({ showDialog: false })
  }

  handleChangeIndustry = (event) => {
    this.setState({ industry: event.target.value })
  }

  handleChangeFileLogo = (files) => {
    this.setState({ logoFile: files[0] })
  }

  handleChangeNameCompany = (event) => {
    this.setState({ nameCompany: event.target.value })
  }

  handleFilterChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  applyFilters = (company) => {
    let active = company.active ? 'active' : 'notActive'
    if(this.state.filterState === 'all' && this.state.filterIndustry === 'all' && this.state.filterName === '')
      return true
    if(this.state.filterState !== 'all' && this.state.filterState !== active)
      return false
    if(this.state.filterIndustry !== 'all' && this.state.filterIndustry !== company.industry)
      return false
    if(this.state.filterName !== '' && company.name.toLowerCase().indexOf(this.state.filterName.toLowerCase()) === -1)
      return false
    return true
  }

  editCompany = async () => {
    /*
     * Validate.
     */
    if (this.state.nameCompany.length < 2) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Por favor, completa el campo "Nombre empresa" correctamente.'
      )
      return
    }
    if (this.state.industry === '') {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Por favor, completa el campo "Rubro" correctamente.'
      )
      return
    }
    /*
     * Upload image.
     */
    let logoUrl = ''
    if (this.state.logoFile !== null && this.state.logoFile !== undefined) {
      const snapshot = await storage.ref()
        .child(`logos/${this.state.logoFile.name}`)
        .put(this.state.logoFile)
      logoUrl = await snapshot.ref.getDownloadURL()
    }
    /*
     * Update company.
     */
    const nameCompanySlug = slugify(this.state.nameCompany, { lower: true })
    try {
      await firestore.collection('companies')
        .doc(this.state.companyId).update({
        name: this.state.nameCompany,
        industry: this.state.industry,
        slug: nameCompanySlug,
        logo: logoUrl,
      })
    } catch (error) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
      return
    }
    /*
     * Notification of success.
     */
    await this.context.setContext('showNotification', true)
    await this.context.setContext('typeNotification', 'success')
    await this.context.setContext(
      'messageNotification',
      'Empresa editada con éxito.'
    )
    /*
     * Recargar la página.
     */
    window.location.reload()
  }

  error = async () => {
    await this.context.setContext('showNotification', true)
    await this.context.setContext('typeNotification', 'error')
    await this.context.setContext(
      'messageNotification',
      'Hubo un error, por favor inténtalo más tarde.'
    )
  }

  render() {
    const { classes } = this.props
    if (this.state.companies === null) {
      return <Loader />
    }
    return (
      <React.Fragment>
        <Grid container spacing={32} className="filters">
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              id="outlined-select-currency"
              select
              label="Estado"
              name="filterState"
              className={classes.textField}
              value={this.state.filterState}
              onChange={this.handleFilterChange}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              variant="outlined"
            >
              {states.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              id="outlined-select-currency"
              select
              label="Industria"
              name="filterIndustry"
              className={classes.textField}
              value={this.state.filterIndustry}
              onChange={this.handleFilterChange}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              variant="outlined"
            >
              {this.state.industriesForFilter.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              id="outlined-company-name-input"
              label="Buscar"
              placeholder="Buscar empresa"
              className={classes.textField}
              name="filterName"
              onChange={this.handleFilterChange}
              autoComplete=""
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon/>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={40}>
          {this.state.companies.filter((company) => this.applyFilters(company)).map((company, index) => {
            const url = generatePath(routesThermometer.list, {
              company: company.slug,
            })
            return (
              <Grid item xs={12} sm={4} key={index}>
                <Link to={url}>
                  <div className="card card--company">
                    <div className="card-body">
                      {company.logo === '' ?
                        <div
                          className="card-box card-box--company"
                          style={{ backgroundImage: 'url("/images/logo-default2.png")' }}
                        />
                      :
                        <div
                          className="card-box card-box--company"
                          style={{ backgroundImage: `url(${company.logo})` }}
                        />
                      }
                      <h2 title={company.active ? "Existe termómetro activo" : "Sin termómetro activo"}>
                        {company.name}
                        <span className={company.active ?
                          'thermometer-point active' : 'thermometer-point'}>
                        </span>
                      </h2>
                      <div className="card-industry">{company.industry}</div>
                      <div className="card-buttons">
                        <div
                          id={'company-' + company.id}
                          style={{ display: 'none' }}
                        >
                          <div className="name">{company.name}</div>
                          <div className="industry">
                            {company.industry}
                          </div>
                        </div>
                        <Icon
                          style={{ right: '0px', color: '#FCB563' }}
                          className="fa fa-pen"
                          onClick={(e) => {
                            this.handleClickOpenDialog(e, company.id)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              </Grid>
            )
          })}
        </Grid>
      <Dialog
        open={this.state.showDialog}
        onClose={this.handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Editar empresa
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Hola!
          </DialogContentText> */}
          <Grid
            container
            spacing={40}
            className="form-admin"
            style={{ marginTop: '10px' }}
          >
            <Grid item xs={6}>
              <div className="form-control">
                <InputLabel htmlFor="component-simple" shrink>
                  Logo
                </InputLabel>
                {/* <input type='file' onChange={this.handleFileSelect} /> */}
                <DropzoneArea
                  dropzoneText=""
                  acceptedFiles={['image/jpeg', 'image/png']}
                  filesLimit={1}
                  maxFileSize={4000000}
                  onChange={this.handleChangeFileLogo}
                />
                <p className="help">
                  Arrastra y suelta un archivo de imagen en el recuadro o
                  haz clic en este.
                </p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-control">
                <InputLabel htmlFor="name-company" shrink>
                  Nombre empresa
                </InputLabel>
                <Input id="name-company"
                  disableUnderline
                  fullWidth
                  value={this.state.nameCompany}
                  onChange={this.handleChangeNameCompany}
                />
              </div>
              <div className="form-control">
                <InputLabel htmlFor="industry-select" shrink>
                  Rubro
                </InputLabel>
                <Select
                  id="industry-select"
                  value={this.state.industry}
                  onChange={this.handleChangeIndustry}
                  input={<BootstrapInput />}
                  className="select"
                >
                  <MenuItem value=""></MenuItem>
                  {this.state.industries.map((industry, index) => {
                    return (
                      <MenuItem value={industry.name} key={index}>
                        {industry.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={this.editCompany} color="primary" autoFocus>
            Editar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
    )
  }
}

ListCompanyView.contextType = GeneralContext

ListCompanyView.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ListCompanyView)
