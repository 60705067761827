const toShortMonth = {
  0: 'ene',
  1: 'feb',
  2: 'mar',
  3: 'abr',
  4: 'may',
  5: 'jun',
  6: 'jul',
  7: 'ago',
  8: 'sep',
  9: 'oct',
  10: 'nov',
  11: 'dic',
}


export const dateWithShortMonth = (date) => {
  let _date = new Date(date)
  return `${_date.getDate()} ${toShortMonth[_date.getMonth()]} ${_date.getFullYear().toString().substr(2,2)}`
}
