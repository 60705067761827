import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import green from '@material-ui/core/colors/green'

import GeneralContext from '../../general/GeneralContext'


const styles = theme => ({
  close: {
    padding: theme.spacing.unit / 2,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  success: {
    backgroundColor: green[600],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
})

class Notification extends React.Component {

  handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.context.setContext('showNotification', false)
  }
  
  render() {
    const { classes } = this.props
    let Icon = CheckCircleIcon
    switch (this.context.typeNotification) {
      case 'info':
        Icon = InfoIcon
        break
      case 'warning':
        Icon = WarningIcon
        break
      case 'error':
        Icon = ErrorIcon
        break
      default:
        break;
    }

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={this.context.showNotification}
        autoHideDuration={6000}
        onClose={this.handleCloseSnackbar}
      // ContentProps={{
      //   'aria-describedby': 'message-id',
      // }}
      // message={<span id="message-id">Note archived</span>}
      // action={[
      //   <Button key="undo" color="secondary" size="small"
      //     onClick={this.handleCloseSnackbar}
      //   >
      //     Cerrar
      //     </Button>,
      //   <IconButton
      //     key="close"
      //     aria-label="Close"
      //     color="inherit"
      //     className={classes.close}
      //     onClick={this.handleCloseSnackbar}
      //   >
      //     <CloseIcon />
      //   </IconButton>,
      // ]}
      >
        <SnackbarContent
          className={classNames({
            [classes.success]: this.context.typeNotification === 'success',
            [classes.info]: this.context.typeNotification === 'info',
            [classes.warning]: this.context.typeNotification === 'warning',
            [classes.error]: this.context.typeNotification === 'error',
          })}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              <Icon
                className={classNames(classes.icon, classes.iconVariant)}
              />
              {this.context.messageNotification}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleCloseSnackbar}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
        />
      </Snackbar>
    )
  }
}

Notification.contextType = GeneralContext
Notification.propTypes = {
  classes: PropTypes.object,
  showSnackbar: PropTypes.bool,
  errorMessage: PropTypes.string,
}

export default withStyles(styles)(Notification)
