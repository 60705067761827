import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { generatePath } from 'react-router-dom'
import slugify from 'slugify'
import { Grid, Button, MenuItem, Paper, TextField } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

import GeneralContext from '../../general/GeneralContext'
import AdminLayout from '../../admin/AdminLayout'
import CountrySelect from '../../../styling/CountrySelect'
import { firestore, storage } from '../../../firebase/firebase'
import routesThermometer from '../../thermometer/routesThermometer'

class AddCompanyView extends Component {

  constructor(props) {
    super(props)
    this.state = {
      industries: [],
      nameCompany: 'Empresa',
      employeeNumber: 0,
      revenue: 0,
      industry: '',
      logoFile: null,
      country: {},
    }
  }

  componentDidMount = async () => {
    await this.context.setContext('nameCompany', '')
    let industriesSnapshot
    try {
      industriesSnapshot = await firestore.collection('industries').orderBy('name').get()
    } catch {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
      return
    }
    await this.setState({
      industries: industriesSnapshot.docs.map(doc => doc.data().name),
      industry: industriesSnapshot.docs[0].data().name
    })
  }

  handleChangeIndustry = (event) => {
    this.setState({ industry: event.target.value })
  }

  handleChangeFileLogo = (files) => {
    this.setState({ logoFile: files[0] })
  }

  handleTextChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleCountrySelect = (country) => {
    this.setState({country})
  }

  addCompany = async () => {
    /*
     * Validate.
     */
    if (this.state.nameCompany.length < 2 || this.state.nameCompany === 'Empresa') {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Por favor, completa el campo "Nombre empresa" correctamente.'
      )
      return
    }
    if (this.state.industry === '') {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Por favor, completa el campo "Rubro" correctamente.'
      )
      return
    }
    /*
     * Upload image.
     */
    let logoUrl = ''
    if (this.state.logoFile !== null && this.state.logoFile !== undefined) {
      const snapshot = await storage.ref()
                              .child(`logos/${this.state.logoFile.name}`)
                              .put(this.state.logoFile)
      logoUrl = await snapshot.ref.getDownloadURL()
    }
    /*
     * Create company.
     */
    const nameCompanySlug = slugify(this.state.nameCompany, { lower: true })
    try {
      await firestore.collection('companies').add({
        active: false,
        name: this.state.nameCompany,
        industry: this.state.industry,
        slug: nameCompanySlug,
        logo: logoUrl,
      })
    } catch (error) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
      return
    }
    /*
     * Notification of success.
     */
    await this.context.setContext('showNotification', true)
    await this.context.setContext('typeNotification', 'success')
    await this.context.setContext(
      'messageNotification',
      'Empresa creada con éxito.'
    )
    /*
     * Redireccionar.
     */
    this.props.history.push(generatePath(routesThermometer.add, {
      company: nameCompanySlug,
    }))
  }

  render() {
    return (
      <AdminLayout title={"Empresas"} subtitle="Bienvenido a Termómetro by Innspiral!">
        <h1 className="h1-admin">Agregar nueva empresa</h1>
        <Paper style={{padding: '2em'}}>
          <Grid container className="form-admin">
            <Grid item xs={6}>
              <Grid container alignItems="center" justify="center" style={{height: '100%'}}>
                <input
                  accept={['image/jpeg', 'image/png']}
                  id="company-logo"
                  type="file"
                  style={{display: 'none'}}
                  onChange={this.handleChangeFileLogo}
                />
                <label htmlFor="company-logo">
                  <div style={{width: '11em', height: '11em', backgroundColor: '#979797', borderRadius: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}>
                    <CloudUploadIcon style={{ fontSize: 100, color: 'black'}}/>
                  </div>
                </label>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container alignItems="center">
                <div className="form-control">
                  <TextField
                    variant="outlined"
                    value={this.state.nameCompany}
                    name="nameCompany"
                    onChange={this.handleTextChange}
                    label="Nombre empresa"
                    inputProps={{
                      className: 'custom-input'
                    }}
                  />
                </div>
                <div className="form-control">
                  <CountrySelect label="País de procedencia" onChange={this.handleCountrySelect} style={{width:'15em', marginTop: '1em'}} />
                </div>
                <div className="form-control">
                  <TextField
                    variant="outlined"
                    value={this.state.employeeNumber}
                    name="employeeNumber"
                    onChange={this.handleTextChange}
                    type="number"
                    label="Número de empleados"
                    inputProps={{
                      className: 'custom-input'
                    }}
                    style={{width: '12em', marginTop: '1em'}}
                  />
                </div>
                <div className="form-control">
                  <TextField
                    variant="outlined"
                    value={this.state.revenue}
                    name="revenue"
                    onChange={this.handleTextChange}
                    type="number"
                    label="Facturación anual (MM CLP)"
                    inputProps={{
                      className: 'custom-input'
                    }}
                    style={{width: '15em', marginTop: '1em'}}
                  />
                </div>
                <div className="form-control">
                  <TextField
                    id="industry-select"
                    name="industry"
                    select
                    variant="outlined"
                    label="Rubro"
                    value={this.state.industry}
                    onChange={this.handleChangeIndustry}
                    inputProps={{
                      className: 'custom-input'
                    }}
                    style={{width: '15em', marginTop: '1em'}}
                  >
                    {this.state.industries.map((industry, index) => {
                      return   <MenuItem value={industry} key={index}> {industry} </MenuItem>
                    })}
                  </TextField>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ textAlign: 'right', marginTop: '3em' }}>
          <Button
            variant="contained"
            className="btn-primary"
            onClick={this.addCompany}
          >
            Agregar empresa
          </Button>
        </div>
      </AdminLayout>
    )
  }
}

AddCompanyView.contextType = GeneralContext
AddCompanyView.propTypes = {
  history: PropTypes.object,
}

export default AddCompanyView
