import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  tableCellHeader: {
    backgroundColor: "#008aa4",
    color: "#ffff",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "13px",
    fontFamily: "Raleway"
  },
  tableCellBody: {
    textAlign: "center",
    fontSize: "13px",
    fontFamily: "Raleway"
  },

})

class TablaBrechaPorPilar extends Component {
  
  constructor(props) {
    super(props)
    const cloneQuestionsArray = this.props.questionsArray
    const practices = cloneQuestionsArray.splice(0, 5)
    this.state = { practices }
  }

  render() {
    const { practices } = this.state
    const { classes } = this.props
    return (
      <Paper classes={{root: classes.root}}>
        <Table style={{ borderRadius: "5px" }}>
          <TableHead>
            <TableRow>
              <TableCell classes={{root: classes.tableCellHeader}}>Práctica</TableCell>
              <TableCell classes={{root: classes.tableCellHeader}}>Brecha</TableCell>
              <TableCell classes={{root: classes.tableCellHeader}}>Pilar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {practices.map(practice => (
              <TableRow key={practice.practice}>
                <TableCell classes={{root: classes.tableCellBody}}>
                  {practice.practice}
                </TableCell>
                <TableCell classes={{root: classes.tableCellBody}}>
                  <strong>
                    {practice.gap.toFixed(2)} pts
                  </strong>
                </TableCell>
                <TableCell classes={{root: classes.tableCellBody}}>
                  {practice.pillar}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}

TablaBrechaPorPilar.propTypes = {
  questionsArray: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TablaBrechaPorPilar)
