import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { auth, firestore } from './firebase/firebase'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import HomeView from './modules/survey/views/HomeSurveyView'
import BeforeView from './modules/survey/views/BeforeSurveyView'
import ThanksView from './modules/survey/views/ThanksSurveyView'
import CommentsView from './modules/survey/views/CommentsSurveyView'
import SurveyView from './modules/survey/views/SurveyView'

import GeneralContext from './modules/general/GeneralContext'
import Notification from './modules/general/components/Notification'
// import Error404 from './modules/error/views/Error404'
import routesSurvey from './modules/survey/routesSurvey'
import { surveyContextValues } from './modules/survey/SurveyContext'
import routesUser from './modules/user/routesUser'
import LoginUserView from './modules/user/views/LoginUserView'
import routesCompany from './modules/company/routesCompany'
import IndexCompanyView from './modules/company/views/IndexCompanyView'
import AddCompanyView from './modules/company/views/AddCompanyView'
import routesThermometer from './modules/thermometer/routesThermometer'
import IndexThermometerView from './modules/thermometer/views/IndexThermometerView'
import AddThermometerView from './modules/thermometer/views/AddThermometerView'
import DetailThermometerView from './modules/thermometer/views/DetailThermometerView'
import WhatThermometerView from './modules/thermometer/views/WhatThermometerView'
import QuestionsThermometerView from './modules/survey/views/QuestionsSurveyView'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1489A7',
    },
    secondary: {
      main: '#FCB563',
    }
  },
  overrides: {
    MuiTabs: {
      root: {
        margin: '4em 0 2em',
      }
    },
    MuiTab: {
      root: {
        fontSize: '20px !important',
        fontWeight: 'normal',
        color: '#9e9e9e !important',
        textTransform: 'none',
        borderBottom: '2px solid #9e9e9e',
      },
      selected: {
        color: '#FCB563 !important'
      }
    },
    MuiInputBase: {
      root: {
        color: '#9e9e9e',
      },
      input: {
        color: 'none',
      }
    }
  }
});

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      nameCompanySlug: '',
      nameCompany: '',
      // thermometers: [],
      companyId: '',
      updateData: true,
      /**
       * Notification.
       */
      showNotification: false,
      typeNotification: 'success',
      messageNotification: '',
      authUserData: null,
    }
  }

  componentDidMount(){
    this.listener = auth.onAuthStateChanged(authUser => {
      authUser
        ? firestore.collection('users').doc(authUser.uid).get().then( async doc => {
            let authUserData = doc.data()
            this.setState({ authUserData })
            if (authUserData.company) {
              let companyDoc = await authUserData.company.get()
              let company = companyDoc.data()
              this.setState({nameCompany: company.name, nameCompanySlug: company.slug, companyId: companyDoc.id})
            }
          }
        )
        : this.setState({ authUserData: null });
    });
  }

  /**
   * Only use to update component.
   */
  setContext = (key, val) => {
    this.setState({ [key]: val })
  }

  render () {
    return (
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <GeneralContext.Provider value={{ ...surveyContextValues, ...this.state, setContext: this.setContext}}>
              {/* Survey Routes */}
              <Route exact path={routesSurvey.home} component={HomeView} />
              <Route exact path={routesSurvey.before} component={BeforeView} />
              <Route exact path={routesSurvey.survey} component={SurveyView} />
              <Route exact path={routesSurvey.comments} component={CommentsView} />
              <Route exact path={routesSurvey.thanks} component={ThanksView} />
              {/* Dashboard Routes */}
              <Route exact path={routesUser.login} component={LoginUserView} />
              {/* Admin Routes */}
              { this.state.loading ? <p>cargando</p> : (
                 this.state.authUserData && ['admin', 'viewer'].some(role => this.state.authUserData.roles.includes(role)) &&
                <Switch>
                  <Route exact path={routesThermometer.list} component={IndexThermometerView} />
                  <Route exact path={routesThermometer.detail} component={DetailThermometerView} />
                  <Route exact path={routesThermometer.what} component={WhatThermometerView} />
                  <Route exact path={routesThermometer.questions} component={QuestionsThermometerView} />
                </Switch>
                
              ) }
              { this.state.authUserData && this.state.authUserData.roles.includes('admin') &&
              <Switch>
                <Route exact path={routesCompany.add} component={AddCompanyView} />
                <Route exact path={routesCompany.list} component={IndexCompanyView} />
                <Route exact path={routesThermometer.add} component={AddThermometerView} />
              </Switch>

              }
              
              {/* <Route exact component={Error404} /> */}
              {/* General Components */}
              <Notification />
            </GeneralContext.Provider>
          </Switch>
        </BrowserRouter>
      </MuiThemeProvider>
    )
  }

}

export default App
