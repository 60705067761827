import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import compose from 'recompose/compose'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'
import Hidden from '@material-ui/core/Hidden'

import GeneralContext from '../../general/GeneralContext'
import SurveyLayout from '../layouts/SurveyLayout'
import routes from '../routesSurvey'
import { firestore } from '../../../firebase/firebase'


const styles = theme => ({
  close: {
    padding: theme.spacing.unit / 2,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
})

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showSnackbar: false,
      errorMessage: '',
    }
  }

  handleStart = async () => {
    const emailElement = document.getElementById('email')
    const email = emailElement.value
    let employeeId
    const employeeSnapshot = await firestore.collection('employees')
      .where('email', '==', email).get()
    employeeSnapshot.docs.forEach(doc => {
      employeeId = doc.id
    })
    if (email === '') {
      this.setState({ showSnackbar: true })
      this.setState({ errorMessage: 'Por favor, completa tu email.' })
      emailElement.focus()
      return
    }
    if (employeeId === undefined) {
      this.setState({ showSnackbar: true });
      this.setState({ errorMessage: 'Email inválido.' })
      emailElement.value = ''
      emailElement.focus()
      return
    }
    this.context.employeeId = employeeId
    this.context.employee = employeeSnapshot.docs[0].data()
    if (employeeSnapshot.docs[0].data().hasOwnProperty('answers')) {
      this.context.employeeCompleted = true
    } else {
      this.context.employeeCompleted = false
    }
    this.props.history.push(routes.before)
    this.getPillarsQuestions()
  }

  handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ showSnackbar: false })
  }

  screenWidthValidation = () => {
    if (this.props.width === "xs" || this.props.width === "sm") {
      return true
    }
    return false
  }

  componentWillMount () {
    this.context.employeeId = ''
  }

  render() {
    const { classes } = this.props
    const Icon = ErrorIcon

    return (
      <SurveyLayout>
        <Hidden smUp>
          <div className="content-home-xs">
            <Grid container spacing={16} justify="center">
              <Grid item xs={12} align="center">
                <img src="./images/logo-termometro.png" height="200" alt="Logo Termómetro" />
              </Grid>
              <Grid item xs={12} align="center">
                <div className="home">
                  <img
                    src="./images/letras_termometro-innspiral.png"
                    alt="Letras termómetro e innspiral"
                  />
                  {/* <Typography color="inherit" variant="h3">TERMÓMETRO</Typography>
                      <Typography color="inherit" variant="h6" align="right">
                        <div className="by">by</div>
                        INNSPIRAL
                      </Typography> */}
                  <Typography color="inherit" variant="h4">
                    Mide la innovación
                  </Typography>
                  <p className="text">
                  Introduce tu email
                  </p>
                  <Input  id="email" disableUnderline={true}
                    placeholder="Ej.: jhon@doe.com"
                    type="email"
                  />
                  <Button
                    className="btn-primary ml-2"
                    variant="contained"
                    size="small"
                    onClick={this.handleStart}
                  >
                    Ir
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Hidden>
        <Hidden xsDown>
          <div className="content-home content-fill-height">
            {/* <SurveyContext.Consumer>
              {context => (
                <p>{context.employeeId}</p>
              )}
            </SurveyContext.Consumer> */}
            <Grid container spacing={40} justify="center" alignItems="flex-end">
              <Grid item sm={3} md={2} align="right">
                <img src="./images/logo-termometro.png" height="350" alt="Logo Termómetro" />
              </Grid>
              <Grid item sm={7} md={5}>
                <div className="home">
                  <img
                    src="./images/letras_termometro-innspiral.png"
                    alt="Letras termómetro e innspiral"
                  />
                  {/* <Typography color="inherit" variant="h3">TERMÓMETRO</Typography>
                  <Typography color="inherit" variant="h6" align="right">
                    <div className="by">by</div>
                    INNSPIRAL
                  </Typography> */}
                  <Typography color="inherit" variant="h4">
                    Mide la innovación
                  </Typography>
                  <p className="text">
                    Introduce tu email
                  </p>
                  <Input  id="email" disableUnderline={true}
                    placeholder="Ej.: jhon@doe.com"
                    type="email"
                  />
                  <Button
                    className="btn-primary ml-2"
                    variant="contained"
                    size="small"
                    onClick={this.handleStart}
                  >
                    Ir
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Hidden>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={this.state.showSnackbar}
          autoHideDuration={6000}
          onClose={this.handleCloseSnackbar}
        >
          <SnackbarContent
            className={classes.error}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
                <Icon
                  className={classNames(classes.icon, classes.iconVariant)}
                />
                {this.state.errorMessage}
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.handleCloseSnackbar}
              >
                <CloseIcon className={classes.icon} />
              </IconButton>,
            ]}
          />
        </Snackbar>
      </SurveyLayout>
    )
  }

  getPillarsQuestions = async () => {
    const pillarsSnapshot = await firestore.collection('pillars')
      .orderBy('order').get()
    const pillars = pillarsSnapshot.docs.map(doc => ({
      name: doc.data().name,
      questions: [],
    }))
    const pillarsLen = pillars.length
    const questionsSnapshot = await firestore.collection('questions')
      .orderBy('order').get()
    questionsSnapshot.docs.forEach(doc => {
      const question = doc.data()
      question.id = doc.id
      for (let i = 0; i < pillarsLen; ++i) {
        if (pillars[i].name === question.pillar) {
          pillars[i].questions.push(question)
        }
      }
    })
    this.context.pillars = pillars
  }

}

Home.contextType = GeneralContext
Home.propTypes = {
  history: PropTypes.object,
  classes: PropTypes.object,
  width: PropTypes.string,
}

export default withStyles(styles)(Home)
