import React, { Component } from 'react'

import Highcharts from 'highcharts'
import HC_More from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types';


HC_More(Highcharts)

class BrechaJerarquicaPorPilar extends Component {

  constructor(props) {
    super(props)
    const pillars = Object.keys(props.pillarsAverageHierarchy)
    const averageAlta = Object.values(props.pillarsAverageHierarchy).map(average => average.averageSystematicityAlta)
    const averageBaja = Object.values(props.pillarsAverageHierarchy).map(average => average.averageSystematicityBaja)
    // const averageAlta = Object.values(props.pillarsAverageAlta).map(average => average.averageGap)
    // const averageBaja = Object.values(props.pillarsAverageBaja).map(average => average.averageGap)
    const alta = []
    const baja = []
    let minIndex = 0
    let maxIndex = 0
    let min = (Math.abs(averageAlta[0] - averageBaja[0]))
    let max = (Math.abs(averageAlta[0] - averageBaja[0]))

    for (let index = 0; index < averageAlta.length - 1; index++) {
      if (min > (Math.abs(averageAlta[index] - averageBaja[index]))) {
        minIndex = index
        min = (Math.abs(averageAlta[index] - averageBaja[index]))
      }
      if (max < (Math.abs(averageAlta[index] - averageBaja[index]))) {
        maxIndex = index
        max = (Math.abs(averageAlta[index] - averageBaja[index]))
      }
    }

    averageAlta.forEach((avg, index) => {
      if(index === minIndex){
        alta.push({marker: { fillColor: '#00FF00', lineWidth: 3, lineColor: "#00FF00" }, y: avg})
      } else if(index === maxIndex) {
        alta.push({marker: { fillColor: '#FF0000', lineWidth: 3, lineColor: "#FF0000" }, y: avg})
      } else {
        alta.push(avg)
      }
    })

    averageBaja.forEach((avg, index) => {
      if(index === minIndex){
        baja.push({marker: { fillColor: '#00FF00', lineWidth: 3, lineColor: "#00FF00" }, y: avg})
      } else if(index === maxIndex) {
        baja.push({marker: { fillColor: '#FF0000', lineWidth: 3, lineColor: "#FF0000" }, y: avg})
      } else {
        baja.push(avg)
      }
    })

    this.state = {
      optionsChart: {
        chart: {
          polar: true,
          type: 'line',
          style: {
            fontFamily: 'Raleway'
          }
        },
        title: {
          text: "",
          align: "center"
        },
        pane: {
          size: '80%'
        },
        xAxis: {
          categories: pillars,
          tickmarkPlacement: 'on',
          lineWidth: 0
        },
        yAxis: {
          gridLineInterpolation: 'polygon',
          lineWidth: 0,
          min: 0,
        },
        tooltip: {
          shared: true,
          pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.2f}</b><br/>'
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          y: 70,
          layout: 'vertical'
        },
        series: [{
          name: 'Alta Jerarquía',
          data: alta,
          pointPlacement: 'on'
        }, {
          name: 'Baja Jerarquía',
          data: baja,
          pointPlacement: 'on'
        }]
      },
    }
  }

  render() {
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={this.state.optionsChart}
      />
    )
  }
}

BrechaJerarquicaPorPilar.propTypes = {
  pillarsAverageHierarchy: PropTypes.object.isRequired
}

export default BrechaJerarquicaPorPilar
