import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

class DialogMessage extends Component {
  render() {
    return (
      <Dialog onClose={this.props.onClose} open={this.props.open}>
        <DialogTitle>
          {this.props.title}
        </DialogTitle>
        <DialogContent>
          {this.props.text}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose()}>Volver</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogMessage.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  text: PropTypes.string
};

export default DialogMessage;