import React, { Component } from 'react'

import Highcharts from 'highcharts'
import HC_More from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types';


HC_More(Highcharts)

class ParticipacionTotal extends Component {
  constructor(props) {
    super(props)
    const completed = props.completed
    const totalCompany = props.totalCompany
    const barColor = (completed / totalCompany) * 100 >= 30 ? "#1489A7" : "#fa6163"
    const secondSerie = ((completed / totalCompany) * 100) < 30 ? 30 - (completed / totalCompany) * 100 : 0
    this.state = {
      optionsChart: {
        chart: {
          renderTo: 'container',
          type: 'bar',
          height: 140,
          style: {
            fontFamily: 'Raleway'
          }
        },
        title: {
          text: ''
        },
        xAxis: {
          enabled: true,
          min: 0,
          categories: [''],
          labels: {
            enabled: true,
          },
        },
        yAxis: {
          enabled: true,
          min: 0,
          title: {
            text: ''
          },
          labels: {
            enabled: false,
          },
          plotLines: []
        },
        credits: false,
        tooltip: false,
        legend: false,
        plotOptions: {
          series: {
            stacking: 'normal'
          }
        },
        series: [{
          data: [100 - (completed / totalCompany) * 100],
          pointWidth: 25,
          borderWidth: 1,
          borderColor: '#303030',
          color: "#FFFF",
          borderRadiusTopLeft: '4px',
          borderRadiusTopRight: '4px',
          borderRadiusBottomLeft: '4px',
          borderRadiusBottomRight: '4px',
          dataLabels: {
            style: {
              textOutline: "0px",
              fontFamily: "Raleway",
              fontSize: 14
            },
            color: "#303030",
            formatter: function () {
              return `${completed}/${totalCompany}`
            },
            enabled: true,
            align: 'right'
          }
        }, {
          data: [secondSerie],
          pointWidth: 25,
          borderWidth: 1,
          borderColor: "#1489A7",
          color: "#FFFF",
          borderRadiusTopLeft: '4px',
          borderRadiusTopRight: '4px',
          borderRadiusBottomLeft: '4px',
          borderRadiusBottomRight: '4px',
          dataLabels: {
            style: {
              textOutline: "0px",
              fontFamily: "Raleway",
              fontSize: 14
            },
            color: "#FFFF",
            formatter: function () {
              return ``
            },
            enabled: true,
            align: 'right'
          }
        }, {
          data: [(completed / totalCompany) * 100],
          pointWidth: 25,
          borderWidth: 1,
          borderColor: barColor,
          color: barColor,
          borderRadiusTopLeft: '4px',
          borderRadiusTopRight: '4px',
          borderRadiusBottomLeft: '4px',
          borderRadiusBottomRight: '4px',
          dataLabels: {
            style: {
              textOutline: "0px",
              fontFamily: "Raleway",
              fontSize: 14
            },
            color: "#FFFF",
            formatter: function () {
              return `${((completed / totalCompany) * 100).toFixed(0)}%`
            },
            enabled: true,
            align: 'right'
          }
        }]
      }
    }
    if(secondSerie !== 0) {
      this.state.optionsChart.yAxis.plotLines.push({
        label: {
          y: 17,
          x: 10,
          text: "Participación mínima",
          rotation: 0,
          align: 'left',
          verticalAlign: 'top',
          style: {
            fontFamily: "Raleway"
          }
        },
        zIndex: 5,
        dashStyle: "solid",
        color: "#1489A7",
        value: 30,
        width: 5
      })
    }
  }

  render() {
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={
          this.state.optionsChart
        }
      />
    )
  }
}

ParticipacionTotal.propTypes = {
  completed: PropTypes.number.isRequired,
  totalCompany: PropTypes.number.isRequired,
}

export default ParticipacionTotal
