import React from 'react'


class Loader extends React.Component {
  render() {
    return (
      <div className="loader">
        <div className="holder">
          <div className="flip-preloader example-1">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="flip-preloader example-2">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="flip-preloader example-3">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    )
  }
}

export default Loader
