import React from 'react'
import PropTypes from 'prop-types'
import { Link, generatePath } from 'react-router-dom'
import { List, ListItem, ListItemText } from '@material-ui/core'
import routesThermometer from '../../thermometer/routesThermometer'
import routesCompany from '../../company/routesCompany'

export default class AdminRoleWithCompany extends React.Component {
  render(){
    let routeThermometerList = generatePath(routesThermometer.list, {
      company: this.props.nameCompanySlug,
    })
    let routeThermometerAdd = generatePath(routesThermometer.add, {
      company: this.props.nameCompanySlug,
    })
    return (
      <React.Fragment>
        <List>
          <ListItem>
            <ListItemText primary="Empresas" className="sidebar-title" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to={routesCompany.list}
            selected={
              this.props.history.location.pathname ===
              routesCompany.list
            }
          >
            <ListItemText primary="Ver todas" className="sidebar-item" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to={routesCompany.add}
            selected={
              this.props.history.location.pathname ===
              routesCompany.add
            }
          >
            <ListItemText primary="Agregar" className="sidebar-item" />
          </ListItem>
        </List>
        <List>
          <ListItem>
            <ListItemText primary={this.props.nameCompany} className="sidebar-title" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to={routeThermometerList}
            selected={
              this.props.history.location.pathname ===
              routeThermometerList
            }
          >
            <ListItemText primary="Ver termómetros" className="sidebar-item"/>
          </ListItem>
          <ListItem
            button
            component={Link}
            to={routeThermometerAdd}
            selected={
              this.props.history.location.pathname ===
              routeThermometerAdd
            }
          >
            <ListItemText primary="Crear termómetro" className="sidebar-item"/>
          </ListItem>
          <List>
            <ListItem>
              <ListItemText primary="Termómetro" className="sidebar-title" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={routesThermometer.what}
              selected={
                this.props.history.location.pathname ===
                routesThermometer.what
              }
            >
              <ListItemText primary="¿Qué es?" className="sidebar-item" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={routesThermometer.questions}
              selected={
                this.props.history.location.pathname ===
                routesThermometer.questions
              }
              classes={{
                selected: 'red'
              }}
            >
              <ListItemText primary="Preguntas" className="sidebar-item" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Usuario" className="sidebar-title" />
            </ListItem>
            <ListItem onClick={this.props.logout} button>
              <ListItemText
                primary="Cerrar Sesión"
                className="sidebar-item"
              />
            </ListItem>
          </List>
        </List>
      </React.Fragment>
    )
  }
}

AdminRoleWithCompany.propTypes = {
  history: PropTypes.object,
  nameCompanySlug: PropTypes.string.isRequired,
  nameCompany: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
}
