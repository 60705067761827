import React from 'react'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core';

import { firestore } from '../../../firebase/firebase'
import AdminLayout from '../../admin/AdminLayout'

const styles = ({
  root: {
    padding: "15px",
    marginTop: "15px",
    marginBottom: "15px",
  }
})

const quizImage = "/images/encuesta.png"
const interviewImage = "/images/entrevista.png"

class WhatThermometerView extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      pillars: [],
      currentPillar: '',
    }
  }

  componentDidMount = async () => {
    const pillarsSnapshot = await firestore.collection('pillars').get()
    const pillars = pillarsSnapshot.docs.map(doc => {
      return ({
        id: doc.id,
        name: doc.data().name,
        description: doc.data().description,
      })
    })
    this.setState({ pillars })
    this.setState({ currentPillar: pillars[0].name })
  }

  handleClickSelectPillar = (event) => {
    this.setState({ currentPillar: event.target.innerText })
  }

  showDescription = () => {
    const aux = this.state.pillars.find(pillar => (
      pillar.name === this.state.currentPillar
    ))
    if (!aux) {
      return ''
    }
    return aux.description
  }

  render() {
    const { classes } = this.props
    return (
      <AdminLayout title={"¿Qué es Termómetro?"} subtitle="Bienvenido a Termómetro by Innspiral!">
        <h1 className="h1-admin" style={{ marginBottom: '20px' }}>
          Termómetro
        </h1>
        <Grid container spacing={40} style={{ marginBottom: '20px' }}>
          <Grid item xs={12} sm={7}>
            El termómetro es un instrumento que mide el grado de desarrollo
            de las capacidades de gestión de innovación, basado en 5 pilares.
          </Grid>
        </Grid>
        <h2 className="h2-admin" style={{ marginBottom: '50px' }}>
          Seleccionar pilar
        </h2>
        <div className="select-pillar">
          {this.state.pillars.map(pillar => (
            <React.Fragment key={pillar.id}>
              {/* <div className="btn">{pillar}</div> */}
              {this.state.currentPillar === pillar.name
                ?
                <div
                  className="btn active"
                  onClick={this.handleClickSelectPillar}
                >
                  {pillar.name}
                </div>
                :
                <div
                  className="btn"
                  onClick={this.handleClickSelectPillar}
                >
                  {pillar.name}
                </div>
              }
            </React.Fragment>
          ))}
        </div>
        <hr />
        <h1 className="h1-admin-yellow">
          {this.state.currentPillar}
        </h1>
        <Grid container spacing={40} style={{ marginBottom: '20px' }}>
          <Grid item xs={12} sm={7}>
            {this.showDescription()}
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={40} style={{ marginBottom: '20px' }}>
          <Grid item xs={12} sm={7}>
            Termómetro se compone por una encuesta a la orgnización y se
            complementa con entrevistas:
          </Grid>
          <Grid item xs={12} sm={6}>
            <h1 className="h1-admin-yellow">Encuesta</h1>
            <Grid container>
              <Grid item xs={12} lg={7}>
                <img className="whatIsImage" src={quizImage} alt={quizImage} />
              </Grid>
              <Grid item xs={12} lg={5}>
                <ul>
                  <li>Online</li>
                  <li>Muestra representativa de cada área y posición, requiere un 30% de respuestas por parte de la organizacón</li>
                  <li>Se miden los 5 pilares en 37 prácticas de la innovación</li>
                  <li>Datos cuantitativos</li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <h1 className="h1-admin-yellow">Entrevista</h1>
            <Grid container>
              <Grid item xs={12} lg={7}>
                <img className="whatIsImage" src={interviewImage} alt={interviewImage} />
              </Grid>
              <Grid item xs={12} lg={5}>
                <ul>
                  <li>Entrevista personal</li>
                  <li>3 colaboradores de la alta gerencia, relacionados con la innovación</li>
                  <li>Se miden los 5 pilares en 12 prácticas de la innovación</li>
                  <li>Datos cualitativos</li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={40} style={{ marginBottom: '20px' }}>
          <Grid item xs={12} sm={7}>
            En la encuesta de medición, los 5 pilares de innovación se miden
            de acuerdo a 2 criterios y una escala de 4 niveles.
          </Grid>
        </Grid>
        <Grid container spacing={40}>
          <Grid item xs={12} sm={4}>
            <h1 className="h1-admin-yellow">Criterios de evaluación</h1>
          </Grid>
          <Grid item xs={12} sm={4}>
            <h1 className="h1-admin-yellow">Escala de evaluación</h1>
          </Grid>
        </Grid>
        <Grid container spacing={40}>
          <Grid item xs={12} sm={4}>
            <h2>Sistematicidad</h2>
            <span>
              Es la frecuencia y grado de formalidad con el cuál se realizan ciertas prácticas dentro de la organización.
            </span>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Paper classes={{ root: classes.root }}>
              <h3 className="blue-subtitle">Sistematicidad</h3>
              <hr className="blue-line" />
              <Grid container>
                <Grid item xs={3}>
                  <p className="red-text">1</p>
                  <p className="red-text">Nunca</p>
                </Grid>
                <Grid item xs={3}>
                  <p className="purple-text">2</p>
                  <p className="purple-text">Rara vez</p>
                </Grid>
                <Grid item xs={3}>
                  <p className="cyan-text">3</p>
                  <p className="cyan-text">Ocasionalmente</p>
                </Grid>
                <Grid item xs={3}>
                  <p className="blue-text">4</p>
                  <p className="blue-text">Siempre</p>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={40}>
          <Grid item xs={12} sm={4}>
            <h2>Importancia</h2>
            <span>
              Grado de importancia asignada a esta práctica de la organización.
            </span>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Paper classes={{ root: classes.root }}>
              <h3 className="blue-subtitle">Importancia</h3>
              <hr className="blue-line" />
              <Grid container justify="center">
                <Grid item xs={3}>
                  <p className="red-text">1</p>
                  <p className="red-text">Baja</p>
                </Grid>
                <Grid item xs={3}>
                  <p className="purple-text">2</p>
                  <p className="purple-text">Media</p>
                </Grid>
                <Grid item xs={3}>
                  <p className="cyan-text">3</p>
                  <p className="cyan-text">Alta</p>
                </Grid>
                <Grid item xs={3}>
                  <p className="blue-text">4</p>
                  <p className="blue-text">Esencial</p>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </AdminLayout>
    )
  }
}

export default withStyles(styles)(WhatThermometerView)
