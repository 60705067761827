import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 4
  }
})

class ThermometerActiveMessage extends Component {
  render() {
    const { classes } = this.props
    return (
      <Grid container spacing={40} justify="center" alignContent="center" alignItems="center" className={classes.root}>
        <Grid item xs={5}>
          <h1 style={{textAlign: "center"}}>
            Esta información estará disponible una vez que finalice el termómetro.
          </h1>
        </Grid>
      </Grid>
    );
  }
}

ThermometerActiveMessage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ThermometerActiveMessage);