import React, { Component } from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'


class BrechaPorPractica extends Component {

  constructor(props) {
    super(props)
    const practices = []
    const averageSystematicity = []
    const averageImportance = []
    props.practices.forEach(practice => {
      practices.push([props.questions[practice.questionId].short, practice.averageSystematicity, practice.averageImportance])
      averageSystematicity.push(practice.averageSystematicity)
      averageImportance.push(practice.averageImportance)
    })
    const showTooltipValidation = averageImportance.map((a, index) => ({ value: index, checked: false }))


    this.state = {
      optionsChart: {
        chart: {
          type: 'arearange',
          zoomType: 'x',
          scrollablePlotArea: {
            minWidth: 600,
            scrollPositionX: 1
          },
          style: {
            fontFamily: 'Raleway'
          }
        },

        credits: {
          enabled: false
        },

        title: {
          text: ''
        },

        xAxis: {
          type: 'category',
          title: {
            text: "PRÁCTICAS",
            align: 'high'
          },
          labels: {
            formatter: function(){
                console.log(this.value);
                    return "<span title='"+ this.value +"'>" + this.value + "</span>";
                },
            useHTML: true,
          }
        },

        yAxis: {
          title: {
            text: null
          },
          min: 0,
          max: 4.5,
        },

        tooltip: {
          shared: true,
          crosshairs: true,
          formatter: function () {
            return 'Brecha ' + (practices[this.x][2] - practices[this.x][1]).toFixed(2)
          }
        },
        legend: {
          enabled: true,
          align: "right",
          verticalAlign: "top",
        },

        plotOptions: {
          series: {
            events: {
              legendItemClick: function () {
                return false; // <== returning false will cancel the default action
              }
            },
            dataLabels: {
              enabled: true,
              inside: false,
              overflow: 'none',
              clop: true,
              shape: 'callout',
              backgroundColor: 'rgba(0,0,0,0.8)',
              borderColor: 'rgba(0,0,0,0.9)',
              color: 'rgba(255,255,255,0.75)',
              borderWidth: .5,
              borderRadius: 5,
              y: -10,
              style: {
                fontFamily: 'Helvetica, sans-serif',
                fontSize: '10px',
                fontWeight: 'normal',
                textShadow: 'none'
              },
              formatter: function () {
                const absoluteValue = Math.abs(averageSystematicity[this.x] - averageImportance[this.x]).toFixed(2)
                if (!showTooltipValidation[this.x].checked) {
                  showTooltipValidation[this.x].checked = true
                  return `<strong>${absoluteValue}</strong>`
                } else {
                  return ""
                }
              }
            }
          }
        },

        series: [{
          name: 'Pilares',
          color: '#313131',
          fillOpacity: 0.1,
          data: practices,
          zones: [{
            value: 1,
            color: "#ffff"
          }]
        },
        {
          type: 'line',
          color: 'red',
          dashStyle: 'dash',
          name: 'Sistematicidad',
          dataLabels: false,
          data: averageSystematicity
        }, {
          type: 'line',
          color: 'gray',
          dashStyle: 'dashdot',
          name: 'Importancia',
          dataLabels: false,
          data: averageImportance
        }]

      },
    }
  }

  render() {
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={this.state.optionsChart}
      />
    )
  }
}

BrechaPorPractica.propTypes = {
  questions: PropTypes.object.isRequired,
  practices: PropTypes.array.isRequired,
}

export default BrechaPorPractica
