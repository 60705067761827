import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Deadline from '../charts/Deadline'
import ParticipacionAreaNivel from '../charts/ParticipacionAreaNivel'
import ParticipacionTotal from '../charts/ParticipacionTotal'

class ThermometerProgress extends Component {
  render() {
    return (
      <div>
        <div>
          <div className="questions">
            <div className="number">01</div>
            <div className="body">
              <h2 className="blue-subtitle">Avance Termómetro</h2>
            </div>
          </div>
          <Paper>
            <Deadline
              startDate={this.props.startDate}
              progress={this.props.progress}
              endingDate={this.props.endingDate}
              remainingDays={this.props.remainingDays}
            />
          </Paper>
        </div>
        <div>
          <div className="questions">
            <div className="number">02</div>
            <div className="body">
              <h2 className="blue-subtitle" style={{ marginTop: "40px" }}>Participación requerida</h2>
            </div>
          </div>
          <Grid container alignItems="baseline">
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <h3 className="yellow-subtitle">DESCRIPCIÓN</h3>
            </Grid>
            <Grid item xs={7}>
              El gráfico 1 representa los porcentaje o número de respuestas en comparación al total de miembros de alta jerarquía que tiene la empresa.
            </Grid>
            <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}></Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={7}>
              El gráfico 2 representa el porcentaje o numero de respuestas en comparación al total de empleados de la empresa
            </Grid>
          </Grid>
          <p>
            <strong>Alta jerarquía</strong>
          </p>
          <h2 className="blue-subtitle">
            Han respondido {this.props.totalCompletedHierarchies} personas
            de un total de {this.props.totalCompanyHighHierarchy}
          </h2>
          <ParticipacionTotal
            total={this.props.totalEmployeesHierarchies}
            completed={this.props.totalCompletedHierarchies}
            totalCompany={this.props.totalCompanyHighHierarchy}
          />
          <p>
            <strong>Participación total</strong>
          </p>
          <h2 className="blue-subtitle">
            Han respondido {this.props.totalCompleted} personas
            de un total de {this.props.totalCompanyEmployees}
          </h2>
          <ParticipacionTotal
            total={this.props.totalEmployees}
            completed={this.props.totalCompleted}
            totalCompany={this.props.totalCompanyEmployees}
          />
        </div>
        <div>
          <div className="questions">
            <div className="number">03</div>
            <div className="body">
              <h2 className="blue-subtitle">Participación área/nivel</h2>
            </div>
          </div>
          <Grid container alignItems="baseline">
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <h3 className="yellow-subtitle">DESCRIPCIÓN</h3>
            </Grid>
            <Grid item xs={7}>
              Este gráfico representa el porcentaje o número de respuestas en comparación a las enviadas. Estos números están subdivididos por el avance observado entre cargo y área de la empresa.
            </Grid>
            <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}></Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={7}>
              **Las invitaciones a responder termómetro son enviadas a un porcentaje representativo de la empresa y no el total.
            </Grid>
          </Grid>
          {this.props.chartsParticipation &&
            <ParticipacionAreaNivel
              chartsParticipation={this.props.chartsParticipation}
            />
          }
        </div>
      </div>
    )
  }
}

ThermometerProgress.propTypes = {
  chartsParticipation: PropTypes.object.isRequired,
  startDate: PropTypes.string.isRequired,
  endingDate: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  remainingDays: PropTypes.number.isRequired,
  totalCompletedHierarchies: PropTypes.number.isRequired,
  totalEmployeesHierarchies: PropTypes.number.isRequired,
  totalCompleted: PropTypes.number.isRequired,
  totalEmployees: PropTypes.number.isRequired,
  totalCompanyEmployees: PropTypes.number.isRequired,
  totalCompanyHighHierarchy: PropTypes.number.isRequired,
}

export default ThermometerProgress
