import React, { Component } from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types';

class PracticasMayorBrechaPorPilar extends Component {

  constructor(props) {
    super(props)

    this.state = {
      optionsChart: {
        chart: {
          type: 'bar',
          style: {
            fontFamily: 'Raleway'
          }
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: props.pillars,
          title: {
            text: "PILARES",
            align: 'high',
          }
        },
        yAxis: {
          min: 0,
          max: 4,
          title: {
            text: 'BRECHAS I-S',
            align: 'high',
          },
          labels: {
            overflow: 'justify'
          },
          plotLines: [{
            color: "#e6e6e6",
            width: 1,
            value: 0.5,
          },
          {
            color: "#e6e6e6",
            width: 1,
            value: 1,
          },
          {
            color: "#e6e6e6",
            width: 1,
            value: 1.5,
          },
          {
            color: "#e6e6e6",
            width: 1,
            value: 2,
          },
          {
            color: "#e6e6e6",
            width: 1,
            value: 2.5,
          },
          {
            color: "#e6e6e6",
            width: 1,
            value: 3,
          },
          {
            color: "#e6e6e6",
            width: 1,
            value: 3.5,
          },
          {
            color: "#e6e6e6",
            width: 1,
            value: 4,
          }]
        },
        legend: {
          enabled: false
        },
        tooltip: {
          style: {
            width: "250px",
            whiteSpace: "wrap"
          },
          formatter: function () {
            return '<b>' + this.point.label + '</b><br>' +
            '<span>' + this.point.question + '</span>'
          }
        },
        plotOptions: {
          bar: {
            colorByPoint: true,
            dataLabels: {
              enabled: true,
              formatter: function () {
                if (this.y >= 0)
                  return this.point.label;
              },
            }
          }
        },
        credits: {
          enabled: false
        },
        series: [
          { data: props.firstRow },
          { data: props.secondRow }
        ]
      }, /* End optionsChart */
    }
  }

  render() {
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={this.state.optionsChart}
      />
    )
  }

}

PracticasMayorBrechaPorPilar.propTypes = {
  firstRow: PropTypes.array.isRequired,
  secondRow: PropTypes.array.isRequired,
  pillars: PropTypes.array.isRequired,
}

export default PracticasMayorBrechaPorPilar
