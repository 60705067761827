import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { List, ListItem, ListItemText } from '@material-ui/core'
import routesThermometer from '../../thermometer/routesThermometer'
import routesCompany from '../../company/routesCompany'

export default class AdminRole extends React.Component {
  render(){
    return (
      <React.Fragment>
        <List>
          <ListItem>
            <ListItemText primary="Empresas" className="sidebar-title" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to={routesCompany.list}
            selected={
              this.props.history.location.pathname ===
              routesCompany.list
            }
          >
            <ListItemText primary="Ver todas" className="sidebar-item" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to={routesCompany.add}
            selected={
              this.props.history.location.pathname ===
              routesCompany.add
            }
          >
            <ListItemText primary="Agregar" className="sidebar-item" />
          </ListItem>
        </List>
        <List>
          <ListItem>
            <ListItemText primary="Termómetro" className="sidebar-title" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to={routesThermometer.what}
            selected={
              this.props.history.location.pathname ===
              routesThermometer.what
            }
          >
            <ListItemText primary="¿Qué es?" className="sidebar-item" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to={routesThermometer.questions}
            selected={
              this.props.history.location.pathname ===
              routesThermometer.questions
            }
            classes={{
              selected: 'red'
            }}
          >
            <ListItemText primary="Preguntas" className="sidebar-item" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Usuario" className="sidebar-title" />
          </ListItem>
          <ListItem onClick={this.props.logout} button>
            <ListItemText
              primary="Cerrar Sesión"
              className="sidebar-item"
            />
          </ListItem>
        </List>
      </React.Fragment>
    )
  }
}

AdminRole.propTypes = {
  history: PropTypes.object,
  logout: PropTypes.func.isRequired,
}
