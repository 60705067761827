import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const now = new Date();
const thisYear = now.getFullYear();
const days = [...Array(31).keys()];
const months = [...Array(12).keys()];
const years = [thisYear, thisYear + 1, thisYear + 2];
const monthToDays = {
  0: 31,
  2: 31,
  3: 30,
  4: 31,
  5: 30,
  6: 31,
  7: 31,
  8: 30,
  9: 31,
  10: 30,
  11: 31,
}

const monthToName = {
  0: 'Enero',
  1: 'Febrero',
  2: 'Marzo',
  3: 'Abril',
  4: 'Mayo',
  5: 'Junio',
  6: 'Julio',
  7: 'Agosto',
  8: 'Septiembre',
  9: 'Octubre',
  10: 'Noviembre',
  11: 'Diciembre',
}

const styles = () => ({
  textInput: {
    borderWidth: '1px',
    borderRadius: '10px',
  },
  dateField: {
    marginRight: '1em',
  }
})
class DatePicker extends React.Component {
  // Set inital parameters
  state = {
    dateDay: 1,
    dateMonth: 0,
    dateYear: thisYear,
  }

  monthTotalDays = (month, year) => {
    // Febraury
    if (month === 1){
      // Check if the current year is a leap year
      let isLeapYear = (year % 100 === 0) ? (year % 400 === 0) : (year % 4 === 0)
      if (isLeapYear)
        return 29;
      return 28;
    }
    return monthToDays[month]
  }

  generateDate = () => {
    return new Date(this.state.dateYear, this.state.dateMonth, this.state.dateDay);
  }

  onDateFieldChange = (event) => {
    let day = this.state.dateDay;
    let name = event.target.name;
    if(name === 'dateMonth'){
      let month = event.target.value;
      let monthTotalDays = this.monthTotalDays(month, this.state.dateYear);
      if(this.state.dateDay > monthTotalDays)
        day = monthTotalDays;
    }
    else if(name === 'dateYear'){
      let year = event.target.value;
      let monthTotalDays = this.monthTotalDays(this.state.dateMonth, year);
      if(this.state.dateDay > monthTotalDays)
        day = monthTotalDays;
    }
    this.setState({
      dateDay: day,
      [name]: event.target.value,
    }, () => this.props.onChange(this.generateDate()))
  }

  render(){
    const { classes } = this.props
    return(
      <div>
        <TextField
          id="day"
          name="dateDay"
          select
          variant="outlined"
          className={classes.dateField}
          label="Día"
          value={this.state.dateDay}
          onChange={this.onDateFieldChange}
          InputProps = {{
            classes: {
              notchedOutline: classes.textInput,
            }
          }}
          inputProps={{
            className: 'custom-input'
          }}
        >
          {days.map(day => {
            // Day plus 1 because the array of days starts with 0
            return <MenuItem key={day + 1} value={day + 1} disabled={day + 1 > this.monthTotalDays(this.state.dateMonth, this.state.dateYear)}>{day + 1}</MenuItem>
          })}
        </TextField>
        <TextField
          id="month"
          name="dateMonth"
          select
          variant="outlined"
          className={classes.dateField}
          label="Mes"
          value={this.state.dateMonth}
          onChange={this.onDateFieldChange}
          InputProps = {{
            classes: {
              notchedOutline: classes.textInput,
            }
          }}
          inputProps={{
            className: 'custom-input'
          }}
        >
          {months.map(month => {
            return <MenuItem key={month} value={month}>{monthToName[month]}</MenuItem>
          })}
        </TextField>
        <TextField
          id="year"
          name="dateYear"
          select
          variant="outlined"
          className={classes.dateField}
          label="Año"
          value={this.state.dateYear}
          onChange={this.onDateFieldChange}
          InputProps = {{
            classes: {
              notchedOutline: classes.textInput,
            }
          }}
          inputProps={{
            className: 'custom-input'
          }}
        >
        {years.map(year => {
          return <MenuItem key={year} value={year}>{year}</MenuItem>
        })}
        </TextField>
      </div>
    );
  }
}

DatePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(DatePicker)
