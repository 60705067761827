import React from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { Tabs, Tab } from '@material-ui/core'
import AddThermometerView from './AddThermometerView'
import ListThermometerView from './ListThermometerView'
import AdminLayout from '../../admin/AdminLayout'

class IndexThermometerView extends React.Component {
  state = {
    value: 0,
    filters: false,
  }

  handleChange = (event, value) => {
    this.setState({ value });
  }

  render(){
    const { value, filters } = this.state
    const { match } = this.props
    return(
      <AdminLayout title={match.params.company} subtitle="Termómetros" logo={this.context.companyLogo}>
        <Tabs
          value={this.state.value}
          indicatorColor="secondary"
          onChange={this.handleChange}
          className="tabs"
        >
          <Tab value={0} label="Ver termómetros"/>
          <Link to='agregar-termometro'>
            <Tab value={1} label="Agregar termómetro"/>
          </Link>
        </Tabs>
        {filters && <div></div>}
        {value === 0 && <ListThermometerView/>}
        {value === 1 && <AddThermometerView/>}
      </AdminLayout>
    )
  }
}

IndexThermometerView.propTypes = {
  match: PropTypes.object.isRequired
}

export default withRouter(IndexThermometerView)
