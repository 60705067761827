import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'


import GeneralContext from '../../general/GeneralContext'
import { firestore } from '../../../firebase/firebase'
import AdminLayout from '../../admin/AdminLayout'

import format from 'date-fns/format'
import es from 'date-fns/locale/es'
import differenceInDays from 'date-fns/difference_in_calendar_days'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import GeneralThermometer from './GeneralThermometer'
import ThermometerPilar from './ThermometerPilar'
import InterviewsThermometerView from './InterviewsThermometerView'
import Loader from '../../general/components/Loader'
import ThermometerProgress from './ThermometerProgress'
import DialogMessage from '../../general/components/DialogMessage'
import ThermometerActiveMessage from './ThermometerActiveMessage'

class DetailThermometerView extends Component {

  constructor(props) {
    super(props)
    this.state = {
      end: false,
      active: true,
      startDate: "",
      endingDate: "",
      remainingDays: 0,
      totalCompletedHierarchies: 0,
      totalEmployeesHierarchies: 0,
      totalCompleted: 0,
      totalEmployees: 0,
      currentTab: 0,
      calculated: false,
      thermometerId: '',
      isLoading: true,
      thermometerNotFound: false,
    }
  }

  openAlert = async (type, message) => {
    await this.context.setContext('showNotification', true)
    await this.context.setContext('typeNotification', type)
    await this.context.setContext('messageNotification', message)
  }

  thermometerNotFound = () => {
    this.setState({ error: false })
    this.props.history.goBack()

  }

  componentDidMount = async () => {
    /**
     * Update this.context.nameCompanySlug
     */
    const { match } = this.props
    const thermometerId = match.params.thermometer
    const nameCompanySlug = match.params.company
    if (nameCompanySlug !== this.context.nameCompanySlug ||
      this.context.nameCompany === '') {
      await this.context.setContext('nameCompanySlug', nameCompanySlug)
      /**
      * Update this.context.nameCompany and this.context.companyId
      */
      let companySnapshot
      try {
        companySnapshot = await firestore.collection('companies')
          .where('slug', '==', this.context.nameCompanySlug).get()
      } catch (error) {
        await this.context.setContext('showNotification', true)
        await this.context.setContext('typeNotification', 'error')
        await this.context.setContext(
          'messageNotification',
          'Hubo un error, por favor inténtalo más tarde.'
        )
        return
      }
      await this.context.setContext('nameCompany', companySnapshot.docs[0].data().name)
      await this.context.setContext('companyId', companySnapshot.docs[0].id)
      await this.context.setContext('companyLogo', companySnapshot.docs[0].data().logo)
    }
    /**
     * Get thermometer
     */
    let thermometerSnapshot
    try {
      thermometerSnapshot = await firestore.collection('thermometers').doc(thermometerId).get()
    } catch (error) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
    }
    if (!thermometerSnapshot.exists) {
      this.setState({ thermometerNotFound: true, isLoading: false })
      return
    }
    const thermometer = thermometerSnapshot.data()
    this.setState({
      thermometerId: thermometerSnapshot.id,
      totalCompanyEmployees: thermometer.totalEmployees,
      totalCompanyHighHierarchy: thermometer.totalHighHierarchy,
      thermometerStatus: thermometer.active,
      thermometerFinalConclusions: thermometer.finalConclusions,
    })
    this.setState({ end: !thermometer.active })

    const endingDate = thermometer.endingDate.toDate()
    const startDate = thermometer.startDate.toDate()
    const totalDays = differenceInDays(endingDate, startDate)
    const completedDays = differenceInDays(new Date(), startDate)
    const remainingDays = totalDays - completedDays

    let progress
    if (remainingDays < 0) {
      progress = 100
    } else {
      progress = (completedDays / totalDays) * 100
    }
    await this.setState({
      startDate,
      endingDate,
      remainingDays,
      progress,
    })
    /**
     * Desactive themometer and company.
     */
    if (thermometer.active && remainingDays < 0) {
      this.setState({ end: true })
      try {
        await firestore.collection('thermometers')
          .doc(thermometerSnapshot.id)
          .update({
            'active': false,
          })
      } catch (error) {
        await this.context.setContext('showNotification', true)
        await this.context.setContext('typeNotification', 'error')
        await this.context.setContext(
          'messageNotification',
          'Hubo un error, por favor inténtalo más tarde.'
        )
        return
      }
      try {
        await firestore.collection('companies')
          .doc(this.context.companyId)
          .update({
            'active': false,
          })
      } catch (error) {
        await this.context.setContext('showNotification', true)
        await this.context.setContext('typeNotification', 'error')
        await this.context.setContext(
          'messageNotification',
          'Hubo un error, por favor inténtalo más tarde.'
        )
        return
      }
    }
    /**
     * chartsParticipation.
     */
    let chartsParticipationSnapshot
    try {
      chartsParticipationSnapshot = await firestore
        .collection('chartsParticipation')
        .where('thermometerId', '==', thermometerSnapshot.id).get()
    } catch (error) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
      return
    }
    const chartsParticipation = chartsParticipationSnapshot.docs[0].data()

    const pillarsSnapshot = await firestore.collection('pillars').orderBy('order', 'asc').get()
    const pillarsThermometer = pillarsSnapshot.docs.map(doc => {
      return ({
        id: doc.id,
        name: doc.data().name,
        description: doc.data().description,
        order: doc.data().order,
      })
    })

    this.setState({
      chartsParticipation,
      totalCompletedHierarchies: chartsParticipation.hierarchies.alta ? chartsParticipation.hierarchies.alta.totalCompleted : 0,
      totalEmployeesHierarchies: chartsParticipation.hierarchies.alta ? chartsParticipation.hierarchies.alta.totalEmployees : 0,
      totalCompleted: chartsParticipation.totalCompleted,
      totalEmployees: chartsParticipation.totalEmployees,
      pillarsThermometer
    })
    this.calculateCharts()
    /* Verify si chartsGeneral esta calculado */
    // Si tiene mas de una propiedad esta calculado
  }

  calculateCharts = async () => {
    /**
     * Get all employees of thermometer.
     */
    let employeesSnapshot
    try {
      employeesSnapshot = await firestore.collection('employees')
        .where('thermometerId', '==', this.state.thermometerId)
        .get()
    } catch (error) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
      return
    }
    /**
     * For each question, sum of all employee responses.
     */
    const questionsSum = {}
    const questionsSumAlta = {}
    const questionsSumBaja = {}

    const questionsSnapshot = await firestore.collection('questions').get()
    const questions = {}
    questionsSnapshot.docs.forEach(question => {
      questions[question.id] = { long: question.data().question, short: question.data().short }
    })

    let totalAnswers = 0
    let totalHighHierarchyAnswers = 0
    let totalLowHierarchyAnswers = 0
    employeesSnapshot.docs.forEach(doc => {
      let employeeData = doc.data()
      if (employeeData.hasOwnProperty('answers') && (employeeData.hierarchy === 'alta' || employeeData.hierarchy === 'alto'))
        totalHighHierarchyAnswers += 1
      if (employeeData.hasOwnProperty('answers') && (employeeData.hierarchy === 'baja' || employeeData.hierarchy === 'bajo'))
        totalLowHierarchyAnswers += 1
      if (employeeData.hasOwnProperty('answers')) {
        totalAnswers += 1
        const questionsEmployee = employeeData.answers
        for (const questionId in questionsEmployee) {
          if (!questionsSum.hasOwnProperty(questionId)) {
            questionsSum[questionId] = {}
            questionsSum[questionId].sumSystematicity = 0
            questionsSum[questionId].sumImportance = 0
            questionsSum[questionId].pillar = questionsEmployee[questionId].pillar
            /* Alta hierarchy */
            questionsSumAlta[questionId] = {}
            questionsSumAlta[questionId].sumSystematicity = 0
            questionsSumAlta[questionId].sumImportance = 0
            questionsSumAlta[questionId].pillar = questionsEmployee[questionId].pillar
            /* Baja hierarchy */
            questionsSumBaja[questionId] = {}
            questionsSumBaja[questionId].sumSystematicity = 0
            questionsSumBaja[questionId].sumImportance = 0
            questionsSumBaja[questionId].pillar = questionsEmployee[questionId].pillar
          }
          questionsSum[questionId].sumSystematicity += questionsEmployee[questionId].systematicity
          questionsSum[questionId].sumImportance += questionsEmployee[questionId].importance
          if (employeeData.hierarchy === 'alta' || employeeData.hierarchy === 'alto') {
            questionsSumAlta[questionId].sumSystematicity += questionsEmployee[questionId].systematicity
            questionsSumAlta[questionId].sumImportance += questionsEmployee[questionId].importance
          }
          if (employeeData.hierarchy === 'baja' || employeeData.hierarchy === 'bajo') {
            questionsSumBaja[questionId].sumSystematicity += questionsEmployee[questionId].systematicity
            questionsSumBaja[questionId].sumImportance += questionsEmployee[questionId].importance
          }
        }
      }
    })

    const questionsAverage = {}
    for (const questionId in questionsSum) {
      questionsAverage[questionId] = {}
      questionsAverage[questionId].averageSystematicity = questionsSum[questionId].sumSystematicity / totalAnswers
      questionsAverage[questionId].averageImportance = questionsSum[questionId].sumImportance / totalAnswers
      questionsAverage[questionId].pillar = questionsSum[questionId].pillar
      questionsAverage[questionId].averageGap = Math.abs(questionsAverage[questionId].averageSystematicity - questionsAverage[questionId].averageImportance)
    }

    const questionsAverageAlta = {}
    for (const questionId in questionsSumAlta) {
      questionsAverageAlta[questionId] = {}
      questionsAverageAlta[questionId].averageSystematicity = questionsSumAlta[questionId].sumSystematicity / totalHighHierarchyAnswers
      questionsAverageAlta[questionId].averageImportance = questionsSumAlta[questionId].sumImportance / totalHighHierarchyAnswers
      questionsAverageAlta[questionId].pillar = questionsSumAlta[questionId].pillar
      questionsAverageAlta[questionId].averageGap = Math.abs(questionsAverageAlta[questionId].averageSystematicity - questionsAverageAlta[questionId].averageImportance)
    }

    const questionsAverageBaja = {}
    for (const questionId in questionsSumBaja) {
      questionsAverageBaja[questionId] = {}
      questionsAverageBaja[questionId].averageSystematicity = questionsSumBaja[questionId].sumSystematicity / totalLowHierarchyAnswers
      questionsAverageBaja[questionId].averageImportance = questionsSumBaja[questionId].sumImportance / totalLowHierarchyAnswers
      questionsAverageBaja[questionId].pillar = questionsSumBaja[questionId].pillar
      questionsAverageBaja[questionId].averageGap = Math.abs(questionsAverageBaja[questionId].averageSystematicity - questionsAverageBaja[questionId].averageImportance)
    }

    const questionsAverageHierarchy = {}
    for (const questionId in questionsSumAlta) {
      questionsAverageHierarchy[questionId] = {}
      questionsAverageHierarchy[questionId].averageSystematicityAlta =
        questionsAverageAlta[questionId].averageSystematicity
      questionsAverageHierarchy[questionId].averageSystematicityBaja =
        questionsAverageBaja[questionId].averageSystematicity
      questionsAverageHierarchy[questionId].averageGap = Math.abs(
        questionsAverageAlta[questionId].averageSystematicity -
        questionsAverageBaja[questionId].averageSystematicity
      )
      questionsAverageHierarchy[questionId].pillar =
        questionsSumAlta[questionId].pillar
    }
    /**
     * Top 6 brecha jerarquica.
     */
    const questionsArrayHierarchy = []
    for (const questionId in questionsAverageHierarchy) {
      questionsArrayHierarchy.push({
        questionId,
        averageSystematicityAlta:
          questionsAverageHierarchy[questionId].averageSystematicityAlta,
        averageSystematicityBaja:
          questionsAverageHierarchy[questionId].averageSystematicityBaja,
        gap: questionsAverageHierarchy[questionId].averageGap,
        pillar: questionsAverageHierarchy[questionId].pillar,
      })
    }

    const questionsArrayAverage = []
    for (const questionId in questionsAverageHierarchy) {
      questionsArrayAverage.push({
        questionId,
        averageSystematicityAlta:
          questionsAverageHierarchy[questionId].averageSystematicityAlta,
        averageSystematicityBaja:
          questionsAverageHierarchy[questionId].averageSystematicityBaja,
        gap: questionsAverageHierarchy[questionId].averageGap,
        pillar: questionsAverageHierarchy[questionId].pillar,
      })
    }
    questionsArrayHierarchy.sort((a, b) => { /* variable to use for charts */
      // return a.gap - b.gap /* menor a mayor */
      return b.gap - a.gap /* mayor a menor */
    })
    //
    const pillarsSumHierarchy = {}
    for (const questionId in questionsAverageHierarchy) {
      const namePillar = questionsAverageHierarchy[questionId].pillar
      if (!pillarsSumHierarchy.hasOwnProperty(namePillar)) {
        pillarsSumHierarchy[namePillar] = {}
        pillarsSumHierarchy[namePillar].sumSystematicityAlta = 0
        pillarsSumHierarchy[namePillar].sumSystematicityBaja = 0
        pillarsSumHierarchy[namePillar].total = 0
      }
      pillarsSumHierarchy[namePillar].sumSystematicityAlta +=
        questionsAverageHierarchy[questionId].averageSystematicityAlta
      pillarsSumHierarchy[namePillar].sumSystematicityBaja +=
        questionsAverageHierarchy[questionId].averageSystematicityBaja
      pillarsSumHierarchy[namePillar].total += 1
    }
    /**
     * Pillars hierarchy.
     */
    const pillarsAverageHierarchy = {}
    for (const namePillar in pillarsSumHierarchy) {
      pillarsAverageHierarchy[namePillar] = {}
      pillarsAverageHierarchy[namePillar].averageSystematicityAlta =
        pillarsSumHierarchy[namePillar].sumSystematicityAlta /
        pillarsSumHierarchy[namePillar].total
      pillarsAverageHierarchy[namePillar].averageSystematicityBaja =
        pillarsSumHierarchy[namePillar].sumSystematicityBaja /
        pillarsSumHierarchy[namePillar].total
      pillarsAverageHierarchy[namePillar].averageGap = Math.abs(
        pillarsAverageHierarchy[namePillar].averageSystematicityAlta -
        pillarsAverageHierarchy[namePillar].averageSystematicityBaja
      )
    }
    //
    const pillarsSum = {}
    for (const questionId in questionsAverage) {
      const namePillar = questionsAverage[questionId].pillar
      if (!pillarsSum.hasOwnProperty(namePillar)) {
        pillarsSum[namePillar] = {}
        pillarsSum[namePillar].sumSystematicity = 0
        pillarsSum[namePillar].sumImportance = 0
        pillarsSum[namePillar].total = 0
      }
      pillarsSum[namePillar].sumSystematicity += questionsAverage[questionId].averageSystematicity
      pillarsSum[namePillar].sumImportance += questionsAverage[questionId].averageImportance
      pillarsSum[namePillar].total += 1
    }
    const pillarsSumAlta = {}
    for (const questionId in questionsAverageAlta) {
      const namePillar = questionsAverageAlta[questionId].pillar
      if (!pillarsSumAlta.hasOwnProperty(namePillar)) {
        pillarsSumAlta[namePillar] = {}
        pillarsSumAlta[namePillar].sumSystematicity = 0
        pillarsSumAlta[namePillar].sumImportance = 0
        pillarsSumAlta[namePillar].total = 0
      }
      pillarsSumAlta[namePillar].sumSystematicity += questionsAverageAlta[questionId].averageSystematicity
      pillarsSumAlta[namePillar].sumImportance += questionsAverageAlta[questionId].averageImportance
      pillarsSumAlta[namePillar].total += 1
    }
    const pillarsSumBaja = {}
    for (const questionId in questionsAverageBaja) {
      const namePillar = questionsAverageBaja[questionId].pillar
      if (!pillarsSumBaja.hasOwnProperty(namePillar)) {
        pillarsSumBaja[namePillar] = {}
        pillarsSumBaja[namePillar].sumSystematicity = 0
        pillarsSumBaja[namePillar].sumImportance = 0
        pillarsSumBaja[namePillar].total = 0
      }
      pillarsSumBaja[namePillar].sumSystematicity += questionsAverageBaja[questionId].averageSystematicity
      pillarsSumBaja[namePillar].sumImportance += questionsAverageBaja[questionId].averageImportance
      pillarsSumBaja[namePillar].total += 1
    }
    /**
     * Brecha por pilar.
     */
    const pillarsAverage = {}
    for (const namePillar in pillarsSum) {
      const pillar = this.state.pillarsThermometer.find(pillar => pillar.name === namePillar)
      pillarsAverage[namePillar] = {}
      pillarsAverage[namePillar].averageSystematicity = pillarsSum[namePillar].sumSystematicity / pillarsSum[namePillar].total
      pillarsAverage[namePillar].averageImportance = pillarsSum[namePillar].sumImportance / pillarsSum[namePillar].total
      pillarsAverage[namePillar].averageGap = Math.abs(pillarsAverage[namePillar].averageSystematicity - pillarsAverage[namePillar].averageImportance)
      pillarsAverage[namePillar].order = pillar.order
    }
    const pillarsAverageAlta = {}
    for (const namePillar in pillarsSumAlta) {
      pillarsAverageAlta[namePillar] = {}
      pillarsAverageAlta[namePillar].averageSystematicity = pillarsSumAlta[namePillar].sumSystematicity / pillarsSumAlta[namePillar].total
      pillarsAverageAlta[namePillar].averageImportance = pillarsSumAlta[namePillar].sumImportance / pillarsSumAlta[namePillar].total
      pillarsAverageAlta[namePillar].averageGap = Math.abs(pillarsAverageAlta[namePillar].averageSystematicity - pillarsAverageAlta[namePillar].averageImportance)
    }
    const pillarsAverageBaja = {}
    for (const namePillar in pillarsSumBaja) {
      pillarsAverageBaja[namePillar] = {}
      pillarsAverageBaja[namePillar].averageSystematicity = pillarsSumBaja[namePillar].sumSystematicity / pillarsSumBaja[namePillar].total
      pillarsAverageBaja[namePillar].averageImportance = pillarsSumBaja[namePillar].sumImportance / pillarsSumBaja[namePillar].total
      pillarsAverageBaja[namePillar].averageGap = Math.abs(pillarsAverageBaja[namePillar].averageSystematicity - pillarsAverageBaja[namePillar].averageImportance)
    }
    /**
     * Ranking de practicas con mayor brecha.
     */
    const questionsArray = []
    for (const questionId in questionsAverage) {
      questionsArray.push({
        questionId,
        gap: questionsAverage[questionId].averageGap,
        averageSystematicity: questionsAverage[questionId].averageSystematicity,
        averageImportance: questionsAverage[questionId].averageImportance,
        pillar: questionsAverage[questionId].pillar,
        practice: questions[questionId].short
      })
    }
    questionsArray.sort((a, b) => {
      // return a.gap - b.gap /* menor a mayor */
      return b.gap - a.gap /* mayor a menor */
    })
    /**
     * Box plot Alta hierarchy
     */
    const questionsArrayAlta = []
    for (const questionId in questionsAverageAlta) {
      questionsArrayAlta.push({
        questionId,
        gap: questionsAverageAlta[questionId].averageGap,
        pillar: questionsAverageAlta[questionId].pillar,
      })
    }
    questionsArrayAlta.sort((a, b) => {
      // return a.gap - b.gap /* menor a mayor */
      return b.gap - a.gap /* mayor a menor */
    })
    /**
     * Box plot Baja hierarchy
     */
    const questionsArrayBaja = []
    for (const questionId in questionsAverageBaja) {
      questionsArrayBaja.push({
        questionId,
        gap: questionsAverageBaja[questionId].averageGap,
        pillar: questionsAverageBaja[questionId].pillar,
      })
    }
    questionsArrayBaja.sort((a, b) => {
      // return a.gap - b.gap /* menor a mayor */
      return b.gap - a.gap /* mayor a menor */
    })
    /**
     * Ranking de practicas con mayor brecha por pilar.
     */
    const pillarsArray = {}
    for (const questionId in questionsAverage) {
      const namePillar = questionsAverage[questionId].pillar
      if (!pillarsArray.hasOwnProperty(namePillar)) {
        pillarsArray[namePillar] = []
      }
      const pillar = this.state.pillarsThermometer.find(pillar => pillar.name === namePillar)
      pillarsArray[namePillar].push({
        questionId,
        gap: questionsAverage[questionId].averageGap,
        order: pillar.order
      })
    }
    for (const namePillar in pillarsArray) {
      pillarsArray[namePillar].sort((a, b) => {
        // return a.gap - b.gap /* menor a mayor */
        return b.gap - a.gap /* mayor a menor */
      })
    }

    const firstRow = []
    const secondRow = []
    let pillarsOrdened = Object.keys(pillarsArray).map(key => {
      return {
        ...pillarsArray[key],
        pillar: key,
        order: this.state.pillarsThermometer.find(pillar => pillar.name === key).order
      }
    })
    pillarsOrdened = pillarsOrdened.sort((a, b) => a.order - b.order)
    const pillarsOrdenedArray = []
    Object.values(pillarsOrdened).forEach(pillar => {
      pillarsOrdenedArray.push(pillar.pillar)
      firstRow.push({ y: pillar[0].gap, label: questions[pillar[0].questionId].short, question: questions[pillar[0].questionId].long })
      secondRow.push({ y: pillar[1].gap, label: questions[pillar[1].questionId].short, question: questions[pillar[1].questionId].long })
    })

    const topSixQuestionsGap = [...questionsArrayHierarchy].splice(0, 6)
    const innovationProcessQuestions = questionsArrayHierarchy.filter(question => question.pillar === "Proceso de innovación")
    const strategyAndLeadershipQuestions = questionsArrayHierarchy.filter(question => question.pillar === "Estrategia y liderazgo")
    const resourcesQuestions = questionsArrayHierarchy.filter(question => question.pillar === "Recursos")
    const organizationQuestions = questionsArrayHierarchy.filter(question => question.pillar === "Organización")
    const cultureQuestions = questionsArrayHierarchy.filter(question => question.pillar === "Cultura")

    const pillars = Object.keys(pillarsOrdened)


    let sumImportance = 0
    let sumSystematicity = 0
    let count = 0
    for (var id in questionsAverage) {
      sumImportance += questionsAverage[id].averageImportance
      sumSystematicity += questionsAverage[id].averageSystematicity
      count++
    }
    let averageImportance = (sumImportance / count).toFixed(2)
    let averageSystematicity = (sumSystematicity / count).toFixed(2)
    let averageGap = (averageImportance - averageSystematicity).toFixed(2)

    await this.setState({
      calculated: true,
      pillarsAverage,
      pillarsAverageBaja,
      pillarsAverageAlta,
      firstRow,
      secondRow,
      pillars,
      pillarsOrdenedArray,
      topSixQuestionsGap,
      pillarsAverageHierarchy,
      questions,
      questionsArray,
      innovationProcessQuestions,
      strategyAndLeadershipQuestions,
      resourcesQuestions,
      organizationQuestions,
      cultureQuestions,
      averageImportance,
      averageSystematicity,
      averageGap,
      isLoading: false
    })
  }

  handleChange = (event, currentTab) => {
    this.setState({ currentTab })
  }

  render() {
    const { remainingDays, totalCompletedHierarchies, totalCompleted, totalCompanyEmployees, totalCompanyHighHierarchy, averageImportance, averageSystematicity, averageGap } = this.state
    const KPIs = { remainingDays, totalCompletedHierarchies, totalCompleted, totalCompanyEmployees, totalCompanyHighHierarchy, averageImportance, averageSystematicity, averageGap }
    return (
      <AdminLayout
        subtitle={this.state.startDate !== "" ? `Fecha de inicio: ${format(this.state.startDate, 'DD MMMM YYYY', { locale: es })}` : this.state.startDate}
        title={this.context.nameCompany}
        logo={this.context.companyLogo}
        status={this.state.thermometerStatus}
        KPIs={KPIs}
      >
        {this.state.thermometerNotFound && (
          <DialogMessage
            open={this.state.thermometerNotFound}
            title={"Error"}
            text={"El termómetro al que estás intentando ingresar no existe"}
            onClose={() => this.thermometerNotFound}
          />
        )}
        {this.state.isLoading && (
          <Loader />
        )}
        {!this.state.isLoading && (
          <React.Fragment>
            <Tabs
              value={this.state.currentTab}
              onChange={this.handleChange}
              variant="fullWidth"
              indicatorColor="secondary"
            >
              <Tab label="Avance" />
              <Tab label="Vista general" />
              <Tab label="Vista por pilar" />
              <Tab label="Entrevistas" />
            </Tabs>
            {this.state.currentTab === 0 && (
              <ThermometerProgress
                startDate={format(this.state.startDate, "DD-MM-YYYY")}
                endingDate={format(this.state.endingDate, "DD-MM-YYYY")}
                progress={this.state.progress}
                remainingDays={this.state.remainingDays}
                chartsParticipation={this.state.chartsParticipation}
                totalCompletedHierarchies={this.state.totalCompletedHierarchies}
                totalEmployeesHierarchies={this.state.totalEmployeesHierarchies}
                totalCompleted={this.state.totalCompleted}
                totalEmployees={this.state.totalEmployees}
                totalCompanyEmployees={this.state.totalCompanyEmployees}
                totalCompanyHighHierarchy={this.state.totalCompanyHighHierarchy}
              />
            )}
            {this.state.currentTab === 1 && (
              <div>
                {this.state.end ?
                  <GeneralThermometer
                    pillarsAverage={this.state.pillarsAverage}
                    questionsArray={this.state.questionsArray}
                    firstRow={this.state.firstRow}
                    secondRow={this.state.secondRow}
                    pillars={this.state.pillarsOrdenedArray}
                    pillarsAverageHierarchy={this.state.pillarsAverageHierarchy}
                    topSixQuestionsGap={this.state.topSixQuestionsGap}
                    questions={this.state.questions}
                    authUserData={this.context.authUserData}
                    thermometerId={this.state.thermometerId}
                    openAlert={this.openAlert}
                    finalConclusions={this.state.thermometerFinalConclusions}
                  /> : <ThermometerActiveMessage />}
              </div>
            )}
            {this.state.currentTab === 2 && this.state.pillarsThermometer && this.state.questions && this.state.questionsArray && (
              <div>
                {this.state.end ?
                  <ThermometerPilar
                    pillars={this.state.pillarsThermometer}
                    questions={this.state.questions}
                    questionsArray={this.state.questionsArray}
                    strategyAndLeadershipQuestions={this.state.strategyAndLeadershipQuestions}
                    innovationProcessQuestions={this.state.innovationProcessQuestions}
                    organizationQuestions={this.state.organizationQuestions}
                    resourcesQuestions={this.state.resourcesQuestions}
                    cultureQuestions={this.state.cultureQuestions}
                  /> :
                  <ThermometerActiveMessage />
                }
              </div>
            )}
            {this.state.currentTab === 3 && (
              <div>
                {this.state.end ?
                  <InterviewsThermometerView
                    company={this.context.nameCompany}
                    authUserData={this.context.authUserData}
                    thermometerId={this.state.thermometerId}
                    openAlert={this.openAlert}
                  /> :
                  <ThermometerActiveMessage />
                }
              </div>
            )}
          </React.Fragment>
        )}
      </AdminLayout>
    )
  }
}

DetailThermometerView.contextType = GeneralContext
DetailThermometerView.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
}

/* Solve problem of property contextType */
export default Object.assign(withRouter(DetailThermometerView), { contextType: undefined })
