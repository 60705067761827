import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link, generatePath } from 'react-router-dom'

import { DropzoneArea } from 'material-ui-dropzone'

import { Grid, Icon, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, InputLabel, MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { firestore } from '../../../firebase/firebase'
import routesThermometer from '../routesThermometer'
import GeneralContext from '../../general/GeneralContext'
import Loader from '../../general/components/Loader'
import { dateWithShortMonth } from '../../../helpers/DateParser'


const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  menu: {
    width: 200,
  },
})

const states = [
  {value: 'all', label: 'Todos'},
  {value: 'active', label: 'Activos'},
  {value: 'notActive', label: 'Inactivos'},
]

class ListThermometerView extends Component {

  constructor(props) {
    super(props)
    this.state = {
      thermometers: null,
      showDialog: false,
      typeDialog: '',
      active: 'all',
      thermometerId: '',
      filterYear: 0,
      filterState: 'all',
      possibleYears: [],
    }
  }

  componentDidMount = async () => {
    /*
    * Update this.context.nameCompanySlug
    */
    const nameCompanySlug = this.props.history.location.pathname.split('/')[3]
    await this.context.setContext('nameCompanySlug', nameCompanySlug)
    /*
     * Update this.context.nameCompany
     */
    let companySnapshot
    try {
      companySnapshot = await firestore.collection('companies').where('slug', '==', this.context.nameCompanySlug).get()
    } catch (error) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
      return
    }
    await this.context.setContext('nameCompany', companySnapshot.docs[0].data().name)
    await this.context.setContext('companyLogo', companySnapshot.docs[0].data().logo)
    /*
    * Update this.context.companyId
    */
    const companyId = companySnapshot.docs[0].id
    await this.context.setContext('companyId', companyId)
    /*
     * Update this.context.thermometers
     */
    let thermometersSnapshot
    try {
      thermometersSnapshot = await firestore.collection('thermometers').where('companyId', '==', companyId).get()
    } catch (error) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
      return
    }
    let thermometers
    // eslint-disable-next-line no-undef
    let possibleYears = new Set([{label: 'Todos', value: 0}])
    if (thermometersSnapshot.docs.length === 0) {
      thermometers = []
      possibleYears.add((new Date()).getFullYear())
    } else {
      thermometers = thermometersSnapshot.docs.map(doc => {
        let data = doc.data()
        let year = (new Date(data.endingDate)).getFullYear()
        year && possibleYears.add({label: year, value: year})
        return { ...data, id: doc.id, }
      })
    }
    await this.setState({ thermometers: thermometers, possibleYears: [...possibleYears] })
  }

  handleClickOpenDialog = (event, thermometerId, typeDialog) => {
    event.preventDefault()
    this.setState({
      showDialog: true,
      thermometerId,
      typeDialog,
    })
  }

  handleCloseDialog = async () => {
    await this.setState({ showDialog: false })
  }

  handleCloseDialogAccept = async () => {
    if (this.state.typeDialog === 'delete') {
      await this.delete()
    }
    await this.setState({ showDialog: false })
  }

  handleSelectChange = (event) => {
    this.setState({[event.target.name]: event.target.value})
  }

  delete = async () => {
    await this.context.setContext('showNotification', true)
    await this.context.setContext('typeNotification', 'info')
    await this.context.setContext(
      'messageNotification',
      'Estamos eliminando el térmometro, espera por favor'
    )
    /* Delete thermometer */
    try {
      await firestore.collection('thermometers')
        .doc(this.state.thermometerId)
        .delete()
    } catch (error) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
      return
    }
    /* Delete employees */
    try {
      const aux = await firestore.collection('employees')
        .where('thermometerId', '==', this.state.thermometerId)
        .get()
      aux.docs.map(async doc => {
        await firestore.collection('employees')
          .doc(doc.id)
          .delete()
      })
    } catch (error) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
      return
    }
    /* Delete chartsParticipation */
    try {
      const aux = await firestore.collection('chartsParticipation')
        .where('thermometerId', '==', this.state.thermometerId)
        .get()
      aux.docs.map(async doc => {
        await firestore.collection('chartsParticipation')
          .doc(doc.id)
          .delete()
      })
    } catch (error) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
      return
    }
    /* Delete chartsGeneral */
    try {
      const aux = await firestore.collection('chartsGeneral')
        .where('thermometerId', '==', this.state.thermometerId)
        .get()
      aux.docs.map(async doc => {
        await firestore.collection('chartsGeneral')
          .doc(doc.id)
          .delete()
      })
    } catch (error) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
      return
    }
    /* Delete chartsPillars */
    try {
      const aux = await firestore.collection('chartsPillars')
        .where('thermometerId', '==', this.state.thermometerId)
        .get()
      aux.docs.map(async doc => {
        await firestore.collection('chartsPillars')
          .doc(doc.id)
          .delete()
      })
    } catch (error) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
      return
    }
    /* Update active of company */
    let companyActive = false
    try {
      const aux = await firestore.collection('thermometers')
        .where('companyId', '==', this.context.companyId)
        .get()
      for (let i = 0; i < aux.docs.length; ++i) {
        if (aux.docs[i].data().active) {
          companyActive = true
          break
        }
      }
    } catch (error) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
      return
    }
    try {
      await firestore.collection('companies')
        .doc(this.context.companyId)
        .update({
          'active': companyActive,
        })
    } catch (error) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
      return
    }

    await this.context.setContext('showNotification', true)
    await this.context.setContext('typeNotification', 'success')
    await this.context.setContext(
      'messageNotification',
      'Termómetro eliminado con éxito'
    )

    let thermometers = this.state.thermometers
    let toDeleteIndex = this.state.thermometers.findIndex((thermometer) => thermometer.id === this.state.thermometerId)
    thermometers.splice(toDeleteIndex, 1)
    this.setState({thermometers})
  }

  applyFilters = (thermometer) => {
    let active = thermometer.active ? 'active' : 'notActive'
    if(this.state.filterState === 'all' && this.state.filterYear === 0)
      return true
    if(this.state.filterState !== 'all' && active !== this.state.filterState)
      return false
    if(this.state.filterYear !== 0 && (new Date(thermometer.endingDate)).getFullYear() !== this.state.filterYear)
      return false
    return true
  }

  render() {
    const { classes } = this.props
    if(this.state.thermometers === null)
      return (<Loader />)
    return (
      <React.Fragment>
        <Grid className="filters">
          <Grid item xs={6} md={2}>
            <TextField
              onChange={this.handleSelectChange}
              id="outlined-select-currency"
              select
              label="Estado"
              name="filterState"
              className={classes.textField}
              value={this.state.filterState}

              margin="normal"
              variant="outlined"
            >
              {states.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} md={2}>
            <TextField
              onChange={this.handleSelectChange}
              id="outlined-select-currency"
              select
              label="Año"
              name="filterYear"
              className={classes.textField}
              value={this.state.filterYear}

              margin="normal"
              variant="outlined"
            >
              {this.state.possibleYears.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={8}>
          </Grid>
        </Grid>
        <Grid container spacing={40}>
          {this.state.thermometers.filter((thermometer) => this.applyFilters(thermometer)).map((thermometer, index) => {
            const url = generatePath(routesThermometer.detail, {
              company: this.context.nameCompanySlug,
              thermometer: thermometer.id
            })
            return (
              <Grid item xs={12} sm={4} key={index}>
                <Link to={url}>
                  <div className="card card--thermometer">
                    <div className="card-body">
                      <div className="card-box card-box--thermometer">
                        <img
                          src="/images/logo-termometro.png"
                          alt="Logo termómetro"
                        />
                      </div>
                      <h2><b>{dateWithShortMonth(thermometer.startDate.toDate())} - {dateWithShortMonth(thermometer.endingDate.toDate())}</b></h2>
                      <h2>{thermometer.active ? 'Activo' : 'Inactivo'}</h2>
                      <div className="card-buttons">
                        <Icon
                          color="error"
                          style={{ left: '0px' }}
                          className="fa fa-trash-alt"
                          onClick={(event) => this.handleClickOpenDialog(event, thermometer.id, 'delete')}
                          />
                        <Icon
                          style={{ right: '0px', color: '#FCB563' }}
                          className="fa fa-pen"
                          onClick={(event) => this.handleClickOpenDialog(event, thermometer.id, 'edit')}
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              </Grid>
              )
            })}
          </Grid>
        <Dialog
          open={this.state.showDialog}
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {this.state.typeDialog === 'edit' ?
              'Editar termómetro' :
              'Eliminar termómetro'
            }
          </DialogTitle>
          <DialogContent>
            {this.state.typeDialog === 'edit' ?
              <Grid container spacing={24} className="form-admin">
                <Grid item xs={6}>
                  <div className="form-control">
                    <InputLabel htmlFor="name-company" shrink>
                      Fecha inicio
                  </InputLabel>
                    <TextField
                      id="date"
                      label=""
                      type="date"
                      defaultValue=""
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.handleChangeStartDate}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="form-control">
                    <InputLabel htmlFor="name-company" shrink>
                      Fecha cierre
                  </InputLabel>
                    <TextField
                      id="date"
                      label=""
                      type="date"
                      defaultValue=""
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.handleChangeEndingDate}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="form-control csv">
                    <InputLabel htmlFor="component-simple" shrink>
                      Archivo empresa formato xlsx.
                  </InputLabel>
                    <DropzoneArea
                      dropzoneText=""
                      acceptedFiles={['application/vnd.ms-excel',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                      ]}
                      filesLimit={1}
                      maxFileSize={4000000}
                      onChange={this.handleChangeFileCSV}
                    />
                    <p className="help">
                      Arrastra y suelta un archivo de imagen aquí o haz clic.
                  </p>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="form-control">
                    <InputLabel htmlFor="component-simple" shrink>
                      Plantilla formato xlsx
                  </InputLabel>
                    <p style={{ marginTop: '20px' }}>Descargar plantilla</p>
                  </div>
                </Grid>
              </Grid>
            :
              '¿Estás seguro que deseas eliminar termómetro?'
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.handleCloseDialogAccept} color="primary" autoFocus>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

ListThermometerView.contextType = GeneralContext
ListThermometerView.propTypes = {
  history: PropTypes.object,
  classes: PropTypes.object.isRequired,
}

/* Solve problem of property contextType */
export default Object.assign(withRouter(withStyles(styles)(ListThermometerView)), { contextType: undefined })
