import React, { Component } from 'react'

import Highcharts from 'highcharts'
import HC_map from 'highcharts/modules/map'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'


HC_map(Highcharts)

class ParticipacionAreaNivel extends Component {

  constructor(props) {
    super(props)
    let areas = []
    let levels = []
    Object.keys(props.chartsParticipation.areasLevels).forEach(areaLevel => {
      const aux = areaLevel.split("-")
      areas.push(aux[0])
      levels.push(aux[1])
    })
    // eslint-disable-next-line no-undef
    areas = [...new Set(areas)]
    // eslint-disable-next-line no-undef
    levels = [...new Set(levels)]
    const data = []
    areas.forEach((area, areaIndex) => {
      levels.forEach((level, levelIndex) => {
        const key = `${area}-${level}`
        if (props.chartsParticipation.areasLevels[key]) {
          const percentage = props.chartsParticipation.areasLevels[key].totalCompleted / props.chartsParticipation.areasLevels[key].totalEmployees * 100
          data.push({
            x: areaIndex,
            y: levelIndex,
            value: percentage,
            label: percentage.toFixed(0) + '%',
            percentage,
            participation: props.chartsParticipation.areasLevels[key].totalCompleted,
            name: "",
            percentageOn: true
          })
        } else {
          return
        }
      })
    })

    this.state = {
      data,
      optionsChart: {
        chart: {
          type: 'heatmap',
          marginTop: 40,
          marginBottom: 80,
          minWidth: 2400,
          plotBorderWidth: 1,
          style: {
            fontFamily: 'Raleway'
          }
        },
        title: {
          text: '',
          align: 'left'
        },
        xAxis: {
          visible: true,
          categories: areas,
          labels: {
            style: {
              fontFamily: "Raleway",
              textOutline: "0px",
            }
          },
        },
        yAxis: {
          visible: true,
          categories: levels,
          title: null,
          labels: {
            style: {
              fontFamily: "Raleway",
              textOutline: "0px",
            }
          },
        },
        colorAxis: {
          min: 0,
          minColor: "#fa6163",
          maxColor: "#1489a7"
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          margin: 0,
          verticalAlign: 'top',
          y: 25,
          symbolHeight: 280,
        },
        tooltip: {
          formatter: function () {
            if (this.point.percentageOn) {
              return 'Del área <b>' + this.series.xAxis.categories[this.point.x] + '</b> y nivel <br><b>' +
                this.series.yAxis.categories[this.point.y] + '</b> han respondido <br><b>' + this.point.participation + ' persona(s)</b>'
            } else {
              return 'Del área <b>' + this.series.xAxis.categories[this.point.x] + '</b> y nivel <br><b>' +
                this.series.yAxis.categories[this.point.y] + '</b> ha respondido un <br><b>' + this.point.value.toFixed(0) + '%</b>'
            }
          }
        },
        series: [{
          borderWidth: 1,
          borderColor: '#ffffff',
          data,
          dataLabels: {
            enabled: true,
            color: '#FFFF',
            style: {
              fontFamily: "Raleway",
              textOutline: "0px",
            },
            formatter: function () {
              return this.point.label
            }
          }
        }]
      },
      percentage: true
    }
  }

  changePercentageNumber = () => {
    let data = [...this.state.data]
    let optionsChart = { ...this.state.optionsChart }

    data.map(d => {
      if (!this.state.percentage) {
        d.label = d.percentage.toFixed(0) + "%"
        d.percentageOn = true
      } else {
        d.label = d.participation
        d.percentageOn = false
      }
    })

    optionsChart.series = [{
      borderWidth: 1,
      borderColor: '#ffffff',
      data,
      dataLabels: {
        enabled: true,
        color: '#FFFF',
        formatter: function () {
          return this.point.label
        }
      }
    }]
    this.setState({ percentage: !this.state.percentage, optionsChart })
  }

  render() {
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  onClick={this.changePercentageNumber}
                  checked={this.state.percentage}
                  color="primary"
                />
              }
              label={this.state.percentage ? "%" : "Nº"}
            />
          </FormGroup>
        </div>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={'mapChart'}
          options={this.state.optionsChart}
        />
      </div>
    )
  }

}

ParticipacionAreaNivel.propTypes = {
  chartsParticipation: PropTypes.object.isRequired
}

export default ParticipacionAreaNivel
