import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core'
import BrechaPorPractica from '../charts/BrechaPorPractica'
import PracticasBrechaJerarquica from '../charts/PracticasBrechaJerarquica'

const styles = ({
  root: {
    padding: "15px",
    marginTop: "15px",
    marginBottom: "15px",
  },
  paper: {
    marginTop: "15px",
    padding: "5px",
  }
})

class ThermometerPilar extends Component {

  constructor(props) {
    super(props)
    const innovationProcessAverage = props.questionsArray.filter(question => question.pillar === "Proceso de innovación")
    const strategyAndLeadershipAverage = props.questionsArray.filter(question => question.pillar === "Estrategia y liderazgo")
    const resourcesAverage = props.questionsArray.filter(question => question.pillar === "Recursos")
    const organizationAverage = props.questionsArray.filter(question => question.pillar === "Organización")
    const cultureAverage = props.questionsArray.filter(question => question.pillar === "Cultura")

    this.state = {
      pillars: props.pillars.sort((a, b) => a.order - b.order),
      currentPillar: props.pillars[0].name,
      innovationProcessAverage,
      strategyAndLeadershipAverage,
      resourcesAverage,
      organizationAverage,
      cultureAverage
    }
  }

  handleClickSelectPillar = (event) => {
    this.setState({ currentPillar: event.target.innerText })
  }

  render() {
    const { classes } = this.props
    return (
      <div className="thermometer-dashboard-container">
        <h2 className="h2-admin" style={{ marginBottom: '50px' }}>
          Seleccionar pilar
        </h2>
        <div className="select-pillar">
          {this.state.pillars.map(pillar => (
            <React.Fragment key={pillar.id}>
              {/* <div className="btn">{pillar}</div> */}
              {this.state.currentPillar === pillar.name
                ?
                <div
                  className="btn active"
                  onClick={this.handleClickSelectPillar}
                >
                  {pillar.name}
                </div>
                :
                <div
                  className="btn"
                  onClick={this.handleClickSelectPillar}
                >
                  {pillar.name}
                </div>
              }
            </React.Fragment>
          ))}
        </div>
        <hr />
        <div>
          <div className="questions">
            <div className="number">01</div>
            <div className="body">
              <h2 className="blue-subtitle">Brecha I-S por práctica</h2>
            </div>
          </div>
          <Grid container alignItems="baseline">
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <h3 className="yellow-subtitle">DESCRIPCIÓN</h3>
            </Grid>
            <Grid item xs={7}>
              <p style={{ fontSize: "16px" }}>
                Este gráfico representa los promedios obtenidos entre importancia y sistematicidad por cada práctica del pilar {this.state.currentPillar},
                luego la brecha corresponde a la diferencia entre la importancia y sistematicidad.
              </p>
              <br />
              <p style={{ fontSize: "16px" }}>
                No olvidar que Sistematicidad es la frecuencia y grado de formalidad con el cual se realizan ciertas prácticas dentro de
                la organización, es decir, qué tan sistemático es dentro de la compañía.
              </p>
            </Grid>
          </Grid>
          <div style={{ width: "100%" }}>
            {this.state.strategyAndLeadershipAverage && this.state.currentPillar === "Estrategia y liderazgo" && (
              <Paper classes={{ root: classes.paper }}>
                <BrechaPorPractica practices={this.state.strategyAndLeadershipAverage} questions={this.props.questions} />
              </Paper>
            )}
            {this.state.innovationProcessAverage && this.state.currentPillar === "Proceso de innovación" && (
              <Paper classes={{ root: classes.paper }}>
                <BrechaPorPractica practices={this.state.innovationProcessAverage} questions={this.props.questions} />
              </Paper>
            )}
            {this.state.organizationAverage && this.state.currentPillar === "Organización" && (
              <Paper classes={{ root: classes.paper }}>
                <BrechaPorPractica practices={this.state.organizationAverage} questions={this.props.questions} />
              </Paper>
            )}
            {this.state.resourcesAverage && this.state.currentPillar === "Recursos" && (
              <Paper classes={{ root: classes.paper }}>
                <BrechaPorPractica practices={this.state.resourcesAverage} questions={this.props.questions} />
              </Paper>
            )}
            {this.state.cultureAverage && this.state.currentPillar === "Cultura" && (
              <Paper classes={{ root: classes.paper }}>
                <BrechaPorPractica practices={this.state.cultureAverage} questions={this.props.questions} />
              </Paper>
            )}
          </div>
          {/* {this.props.questionsArray && <TablaBrechaPorPractica questionsArray={this.props.questionsArray} /> } */}
        </div>
        <div>
          <div className="questions">
            <div className="number">02</div>
            <div className="body">
              <h2 className="blue-subtitle">Brechas en sistematicidad entre jerarquías por práctica</h2>
            </div>
          </div>
          <Grid container alignItems="baseline">
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <h3 className="yellow-subtitle">DESCRIPCIÓN</h3>
            </Grid>
            <Grid item xs={7}>
              Este gráfico presenta en orden de menor a mayor las brechas en sistematicidad que se obtuvieron entre la alta y baja jerarquía por practica, la brecha corresponde al valor absoluto de la diferencia del promedio que se obtuvo en sistematicidad por la alta jerarquía y el promedio de sistematicidad que se obtuvo por la baja jerarquía.
            </Grid>
            <div style={{ width: "100%" }}>
              {this.props.strategyAndLeadershipQuestions && this.state.currentPillar === "Estrategia y liderazgo" && (
                <Paper classes={{ root: classes.paper }}>
                  <PracticasBrechaJerarquica questionsArrayHierarchy={this.props.strategyAndLeadershipQuestions} questions={this.props.questions} />
                </Paper>
              )}
              {this.props.innovationProcessQuestions && this.state.currentPillar === "Proceso de innovación" && (
                <Paper classes={{ root: classes.paper }}>
                  <PracticasBrechaJerarquica questionsArrayHierarchy={this.props.innovationProcessQuestions} questions={this.props.questions} />
                </Paper>
              )}
              {this.props.organizationQuestions && this.state.currentPillar === "Organización" && (
                <Paper classes={{ root: classes.paper }}>
                  <PracticasBrechaJerarquica questionsArrayHierarchy={this.props.organizationQuestions} questions={this.props.questions} />
                </Paper>
              )}
              {this.props.resourcesQuestions && this.state.currentPillar === "Recursos" && (
                <Paper classes={{ root: classes.paper }}>
                  <PracticasBrechaJerarquica questionsArrayHierarchy={this.props.resourcesQuestions} questions={this.props.questions} />
                </Paper>
              )}
              {this.props.cultureQuestions && this.state.currentPillar === "Cultura" && (
                <Paper classes={{ root: classes.paper }}>
                  <PracticasBrechaJerarquica questionsArrayHierarchy={this.props.cultureQuestions} questions={this.props.questions} />
                </Paper>
              )}
            </div>
          </Grid>
        </div>
      </div>
    )
  }
}

ThermometerPilar.propTypes = {
  classes: PropTypes.object.isRequired,
  pillars: PropTypes.array.isRequired,
  questionsArray: PropTypes.array,
  questions: PropTypes.object,
  strategyAndLeadershipQuestions: PropTypes.array,
  innovationProcessQuestions: PropTypes.array,
  organizationQuestions: PropTypes.array,
  resourcesQuestions: PropTypes.array,
  cultureQuestions: PropTypes.array,
}

export default withStyles(styles)(ThermometerPilar)
