import React from 'react'

import { firestore } from '../../../firebase/firebase'
import AdminLayout from '../../admin/AdminLayout'


class QuestionsThermometerView extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      questions: [],
      pillars: {},
      currentPillar: '',
    }
  }

  componentDidMount = async () => {
    const questionsSnapshot = await firestore.collection('questions')
      .orderBy('order')
      .get()
    const questions = questionsSnapshot.docs.map(doc => {
      return ({
        id: doc.id,
        statement: doc.data().question,
        short: doc.data().short,
        pillar: doc.data().pillar,
        order: doc.data().order,
      })
    })
    this.setState({ questions })
    const pillars = {}
    for (let i = 0; i < questions.length; ++i) {
      if (!pillars.hasOwnProperty(questions[i].pillar)) {
        pillars[questions[i].pillar] = []
      }
      pillars[questions[i].pillar].push({
        id: questions[i].id,
        statement: questions[i].statement,
        short: questions[i].short,
        order: questions[i].order,
      })
    }
    this.setState({ pillars })
    this.setState({ currentPillar: Object.keys(pillars)[0] })
  }

  handleClickSelectPillar = (event) => {
    this.setState({ currentPillar: event.target.innerText })
  }
  
  render() {
    return (
      <AdminLayout title={"Preguntas encuesta"} subtitle="Bienvenido a Termómetro by Innspiral!">
        <h1 className="h1-admin">Seleccionar Pilar</h1>
        <div className="select-pillar">
          {Object.keys(this.state.pillars).map(pillar => (
            <React.Fragment key={pillar}>
              {this.state.currentPillar === pillar
              ?
                <div
                  className="btn active"
                  onClick={this.handleClickSelectPillar}
                >
                  {pillar}
                </div>
              :
                <div
                  className="btn"
                  onClick={this.handleClickSelectPillar}
                >
                  {pillar}
                </div>
              }
            </React.Fragment>
          ))}
        </div>
        <hr />
        <h1 className="h1-admin" style={{ marginBottom: '20px' }}>
          {this.state.currentPillar}
        </h1>
        <p style={{ marginBottom: '20px' }}>
          {"Estas corresponden a las preguntas realizadas en la encuesta Térmometro para el pilar "}
          {`"${this.state.currentPillar.toLocaleLowerCase()}"`}:
        </p>
        {this.state.pillars[this.state.currentPillar] &&
          this.state.pillars[this.state.currentPillar].map((question) => (
          <div key={question.id} className="questions">
            <div className="number">
              {question.order < 10 ? `0${question.order}` : question.order}
            </div>
            <div className="body">
              <h2>{question.short}</h2>
              {question.statement}.
            </div>
          </div>
        ))}
        {/* <Grid container spacing={40}>
          <Grid item xs={12} sm={4}>
            Hola.
          </Grid>
        </Grid> */}
      </AdminLayout>
    )
  }
}

export default QuestionsThermometerView
