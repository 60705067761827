import React, { Component } from 'react'

import { Redirect } from 'react-router-dom'

import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import withWidth from '@material-ui/core/withWidth'
import { withStyles } from '@material-ui/core/styles'
import StepLabel from '@material-ui/core/StepLabel'

import GeneralContext from '../../general/GeneralContext'
import routes from '../routesSurvey'
import SurveyLayout from '../layouts/SurveyLayout'


const stepIcon = "/images/stepperIcon.png"
// StepIconProps={{ classes: { root: classes.stepIcon, completed: classes.stepIcon } }}
const styles = () => ({
  stepper: {
    backgroundColor: "transparent"
  },
  stepIcon: {
    color: "#FCB563",
    fill: "#FCB563"
  },
  stepLabel: {
    color: "#FCB563",
  },
  completed: {
    color: "#FCB563",
  }
})

const StyledStepLabel = withStyles({
  label: {
    color: "#FCB563",
    fill: "#FCB563",
    '&$active': {
      color: "#FCB563",
      fill: "#FCB563",
    },
    '&$completed': {
      color: "#FCB563",
      fill: "#FCB563",
    }
  },
  completed: {},
  active: {}
})(StepLabel)

class Thanks extends Component {

  showThermometer = index => {
    if (index === 7) {
      return <img style={{ height: "70px", marginTop: "-37px", marginBottom: "-10px" }} src={stepIcon} alt={stepIcon} />
    } else {
      return index + 1;
    }
  }

  screenWidthValidation = () => {
    if(this.props.width === "xs" || this.props.width ===  "sm") {
      return true
    }
    return false
  }

  render() {
    if (this.context.employeeId === '') {
      return <Redirect to={routes.home} />
    }
    const steps = [
      'Antes de partir',
      'Proceso de innovación',
      'Estrategia y liderazgo',
      'Organización',
      'Recursos',
      'Cultura',
      'Comentarios',
      '¡Gracias!'
    ]
    const { classes } = this.props

    return (
      <SurveyLayout>
        <Stepper
          id="stepper"
          alternativeLabel
          activeStep={7}
          style={{ marginTop: "30px", marginBottom: "60px" }}
          classes={{ root: classes.stepper }}
        >
          {steps.map((label, index) => {
            const props = {};
            const labelProps = {};
            return (
              <Step key={label} {...props}>
                <StyledStepLabel icon={this.showThermometer(index)} {...labelProps}
                  StepIconProps={{ classes: { root: classes.stepIcon, text: classes.stepIcon } }}>
                  {this.props.width === 'xs' ? '' : label}
                </StyledStepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div className="content">
          <h1 style={{ marginBottom: "50px" }} align="center">
            ¡Muchas gracias!
          </h1>
          <p align="center" style={{ fontSize: "20px", marginBottom: "30px" }}>
            Ya puedes cerrar esta página.
          </p>
          <div align="center" style={{ marginBottom: "50px" }}>
            <Button
              className="btn-primary"
              variant="contained"
              href="https://www.innspiral.com/"
            >
              <img
                src="/images/logo-innspiral-blanco.png"
                alt="Logo Innspiral Blanco"
                width="150"
              />
            </Button>
          </div>
        </div>
      </SurveyLayout>
    )
  }

  componentDidMount() {
    this.context.employeeId = ''
  }
}

Thanks.contextType = GeneralContext
Thanks.propTypes = {
  width: PropTypes.string,
  classes: PropTypes.object,
}

export default withWidth()(withStyles(styles)(Thanks));
