import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core'

const thermometerIcon = "/images/stepperIcon.png"
const styles = () => ({
  root: {
    margin: "-25px -25px 0px -25px"
  },
  kpiValue: {
    textAlign: 'center',
    color: "#008aa4"
  },
  kpiLabel: {
    textAlign: 'center',
  }
})

class Header extends Component {

  getKPIs = () => {
    const { classes, KPIs } = this.props
    if(KPIs.remainingDays < 0)
      return(
        <React.Fragment>
          <hr style={{borderColor: '#9e9e9e', margin: '2em 3em'}}/>
          <Grid container alignItems="baseline" justify="center">
            <Grid item xs={4}>
              <h3 className={classes.kpiLabel}>Importancia promedio</h3>
            </Grid>
            <Grid item xs={4}>
              <h3 className={classes.kpiLabel}>Sistematicidad promedio</h3>
            </Grid>
            <Grid item xs={4}>
              <h3 className={classes.kpiLabel}>Promedio brecha I-S</h3>
            </Grid>
          </Grid>
          <Grid container alignItems="baseline" justify="center">
            <Grid item xs={4}>
              <h3 className={classes.kpiValue}>{KPIs.averageImportance}</h3>
            </Grid>
            <Grid item xs={4}>
              <h3 className={classes.kpiValue}>{KPIs.averageSystematicity}</h3>
            </Grid>
            <Grid item xs={4}>
              <h3 className={classes.kpiValue}>{KPIs.averageGap}</h3>
            </Grid>
          </Grid>
        </React.Fragment>
      )
      else
        return(
          <React.Fragment>
            <hr style={{borderColor: '#9e9e9e', margin: '2em 3em'}}/>
            <Grid container alignItems="baseline" justify="center">
              <Grid item xs={4}>
                <h3 className={classes.kpiLabel}>Días restantes</h3>
              </Grid>
              <Grid item xs={4}>
                <h3 className={classes.kpiLabel}>Participación alta jerarquía</h3>
              </Grid>
              <Grid item xs={4}>
                <h3 className={classes.kpiLabel}>Participación total</h3>
              </Grid>
            </Grid>
            <Grid container alignItems="baseline" justify="center">
              <Grid item xs={4}>
                <h3 className={classes.kpiValue}>{KPIs.remainingDays}</h3>
              </Grid>
              <Grid item xs={4}>
                <h3 className={classes.kpiValue}>{`${KPIs.totalCompletedHierarchies}/${KPIs.totalCompanyHighHierarchy}`}</h3>
              </Grid>
              <Grid item xs={4}>
                <h3 className={classes.kpiValue}>{`${KPIs.totalCompleted}/${KPIs.totalCompanyEmployees}`}</h3>
              </Grid>
            </Grid>
          </React.Fragment>
        )
  }

  render() {
    const { classes, subtitle, title, logo, status } = this.props
    
    return (
      <Paper classes={{ root: classes.root }}>
        <Grid container>
          <Grid item md={2} container justify="center">
            <img className="images-header" src={thermometerIcon} alt={thermometerIcon} />
          </Grid>
          <Grid item md={7}>
            <h3>{subtitle}</h3>
            <h1>{title}</h1>
            {status !== undefined && 
            <h3>
              Estado: 
              {status === true ? " Activo" : " Finalizado"}
            </h3>}
          </Grid>
          <Grid item md={3}>
            {logo && <img className="images-header" src={logo} alt={logo} />}
          </Grid>
        </Grid>
        {this.props.KPIs && this.getKPIs()}
      </Paper>
    )
  }
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  logo: PropTypes.string,
  status: PropTypes.bool,
  KPIs: PropTypes.object,
}

export default withStyles(styles)(Header)
