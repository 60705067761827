import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Redirect } from 'react-router-dom'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import withWidth from '@material-ui/core/withWidth'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'

import GeneralContext from '../../general/GeneralContext'
import routes from '../routesSurvey'
import SurveyLayout from '../layouts/SurveyLayout'


const stepIcon = "/images/stepperIcon.png"
// StepIconProps={{ classes: { root: classes.stepIcon, completed: classes.stepIcon } }}

const styles = () => ({
  stepper: {
    backgroundColor: "transparent"
  },
  stepIcon: {
    color: "#FCB563",
    fill: "#FCB563"
  },
  stepIconXS: {
    color: "#FCB563",
    fill: "#FCB563",
  },
  stepLabel: {
    color: "#FCB563",
  },
  completed: {
    color: "#FCB563",
  }
});

const StyledStepLabel = withStyles({
  label: {
    color: "#FCB563",
    fill: "#FCB563",
    '&$active': {
      color: "#FCB563",
      fill: "#FCB563",
    },
    '&$completed': {
      color: "#FCB563",
      fill: "#FCB563",
    }
  },
  completed: {},
  active: {}
})(StepLabel);

class SurveyView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      responded: [],
      activeStep: 1,
      showSnackbar: false,

    }
  }

  getSteps = () => {
    let steps = ['Antes de partir']
    const pillarsName = this.context.pillars.map(pillar => {
      return pillar.name;
    });
    steps = [...steps, ...pillarsName, 'Comentarios', '¡Gracias!']
    return steps;
  }

  getStepContent = (step) => {
    return this.context.pillars[step].questions
  }

  paintAnswers() {
    const answers = this.context.answers
    for(const questionId in answers) {
      for (const valuationType in answers[questionId]) {
        const id = `${questionId}-${valuationType}-${answers[questionId][valuationType]}`
        const element = document.getElementById(id)
        if (element !== null) {
          element.style.backgroundColor = "#FA6163"
          element.style.color = "#FFFFFF"
        }
      }
    }
  }

  handleBack = () => {
    document.getElementById("stepper").scrollIntoView({ behavior: 'smooth' })
    if (this.state.activeStep === 1) {
      this.props.history.push(routes.before)
      return
    }
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }))
    this.context.activeStep = this.context.activeStep - 1;
  }

  handleNext = () => {
    if (this.state.responded.size !== this.context.pillars[this.state.activeStep - 1].questions.length) {
      this.setState({ showSnackbar: true })
      return
    }
    document.getElementById("stepper").scrollIntoView({ behavior: 'smooth' })
    if (this.state.activeStep < this.context.pillars.length) {
      this.setState(state => ({
        activeStep: state.activeStep + 1,
        responded: []
      }))
      this.context.activeStep = this.state.activeStep + 1
      return 
    }
    this.context.activeStep = this.state.activeStep + 1
    this.props.history.push(routes.comments)
  }

  handleSelect = (e) => {
    /**
     * Get values
     */
    const elementId = e.target.id.split("-")
    const questionId = elementId[0]
    const valuationType = elementId[1]
    const valuationNumber = parseInt(elementId[2])
    const pillarName = elementId[3]
    /**
     * Paint one element
     */
    for (let v of [1, 2, 3, 4]) {
      const element = document
        .getElementById(`${questionId}-${valuationType}-${v}-${pillarName}`)
      element.style.backgroundColor = "#FFFFFF"
      element.style.color = "#000000"
    }
    /**
     * Complete this.context.answers
     */
    // const answer = {}
    // answer[questionId] = {}
    // answer[questionId][valuationType] = valuationNumber
    e.target.style.backgroundColor = "#FA6163"
    e.target.style.color = "#FFFFFF"
    const answers = { ...this.context.answers }
    if (answers.hasOwnProperty(questionId)) {
      answers[questionId][valuationType] = valuationNumber
      answers[questionId].pillar = pillarName
    } else {
      answers[questionId] = {}
      answers[questionId][valuationType] = valuationNumber
      answers[questionId].pillar = pillarName
    }
    this.context.answers = answers
    /**
     * Move on to the next question.
     */
    const questionsElements = document.querySelectorAll('.pregunta')
    const questionsElementsLength = questionsElements.length
    const totalPerQuestion = Object.keys(answers[questionId]).length
    if (totalPerQuestion === 3) {
      for(let i = 0; i < questionsElementsLength; ++i) {
        if(questionsElements[i].id === questionId) {          
          const documentsSelected = [...this.state.responded]
          documentsSelected.push(document.getElementById(questionsElements[i].id))
          // eslint-disable-next-line no-undef
          const responded = new Set(documentsSelected)
          this.setState({ responded })
          if((i+1) < questionsElementsLength) {
            const nextQuestionElement = document.getElementById(questionsElements[i+1].id)
            nextQuestionElement.scrollIntoView({ behavior: 'smooth' })
          }
        }
      }
    }
  }

  showThermometer = index => {
    if (index === this.context.activeStep) {
      return <img style={{ height: "70px", marginTop: "-37px", marginBottom: "-10px" }} src={stepIcon} alt={stepIcon} />
    } else {
      return index + 1;
    }
  }

  screenWidthValidation = () => {
    if(this.props.width === "xs" || this.props.width ===  "sm") {
      return true
    }
    return false
  }

  handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ showSnackbar: false })
  }

  render() {
    if (this.context.employeeId === '') {
      return <Redirect to={routes.home} />
    }
    const steps = this.getSteps()
    const { classes } = this.props;
    let answer = "answer";
    // answer += this.props.width === "xs" || "sm" ? " answer--xs" : "";
    answer += this.screenWidthValidation() ? " answer--xs" : "";
    const spacing = this.screenWidthValidation() ? 8 : 16
    
    return (
      <SurveyLayout>
        <Stepper
          id="stepper"
          alternativeLabel
          activeStep={this.context.activeStep}
          style={{ marginTop: "30px", marginBottom: "60px" }}
          classes={{ root: classes.stepper }}
        >
          {steps.map((label, index) => {
            const props = {};
            const labelProps = {};
            return (
              <Step key={label} {...props} style={{paddingRight: "0px"}}>
                <StyledStepLabel icon={this.showThermometer(index)} {...labelProps}
                  StepIconProps={{ classes: { root: this.screenWidthValidation() ? classes.stepIconXS : classes.stepIcon, text: classes.stepIcon } }}>
                  {this.screenWidthValidation() ? '' : label}
                </StyledStepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div className="content question-scroll">
          <h1 align="center">
            {steps[this.state.activeStep]}
          </h1>
          {this.context.pillars.length &&
          this.context.pillars[this.state.activeStep - 1].questions.map((question, index) =>
            <div key={question.id} id={question.id} className="pregunta">
              <div className="pregunta-number">{index + 1}</div>
              <div className="pregunta-body">
                <div>{question.question}</div>
                <div className="mt-1">
                  <strong>Sistematicidad</strong>
                </div>
                <Grid className="mt-1" container spacing={spacing} align="center">
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      id={
                        question.id +
                        '-systematicity-1-' +
                        this.context.pillars[this.state.activeStep - 1].name
                      }
                      className={answer}
                      onClick={this.handleSelect}
                    >
                      Nunca
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      id={
                        question.id +
                        '-systematicity-2-' +
                        this.context.pillars[this.state.activeStep -1].name
                      }
                      className={answer}
                      onClick={this.handleSelect}
                    >
                      Rara vez
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      id={
                        question.id +
                        '-systematicity-3-' +
                        this.context.pillars[this.state.activeStep -1].name
                      }
                      className={answer}
                      onClick={this.handleSelect}
                    >
                      Ocasionalmente
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      id={
                        question.id +
                        '-systematicity-4-' +
                        this.context.pillars[this.state.activeStep -1].name
                      }
                      className={answer}
                      onClick={this.handleSelect}
                    >
                      Siempre
                    </div>
                  </Grid>
                </Grid>
                <div className="mt-1">
                  <strong>Importancia</strong>
                </div>
                <Grid container spacing={spacing} align="center">
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      id={
                        question.id +
                        '-importance-1-' +
                        this.context.pillars[this.state.activeStep -1].name
                      }
                      className={answer}
                      onClick={this.handleSelect}
                    >
                      Baja
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      id={
                        question.id +
                        '-importance-2-' +
                        this.context.pillars[this.state.activeStep -1].name
                      }
                      className={answer}
                      onClick={this.handleSelect}
                    >
                      Media
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      id={
                        question.id +
                        '-importance-3-' +
                        this.context.pillars[this.state.activeStep -1].name
                      }
                      className={answer}
                      onClick={this.handleSelect}
                    >
                      Alta
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      id={
                        question.id +
                        '-importance-4-' +
                        this.context.pillars[this.state.activeStep -1].name
                      }
                      className={answer}
                      onClick={this.handleSelect}
                    >
                      Esencial
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
        </div>
        <div className="footer">
          <Button
            className="btn-primary"
            variant="contained"
            onClick={this.handleBack}
          >
            Volver
          </Button>
          <Button
            className="btn-primary"
            variant="contained"
            onClick={this.handleNext}
          >
            Continuar
          </Button>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={this.state.showSnackbar}
          autoHideDuration={6000}
          onClose={this.handleCloseSnackbar}
        >
          <SnackbarContent
            className={classes.error}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
                <Icon
                  className={classNames(classes.icon, classes.iconVariant)}
                />
                {"Faltan preguntas por responder"}
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.handleCloseSnackbar}
              >
                <CloseIcon className={classes.icon} />
              </IconButton>,
            ]}
          />
        </Snackbar>
      </SurveyLayout>
    )
  }

  componentDidMount = () => {
    this.setState({ activeStep: this.context.activeStep })
  }
  
  componentDidUpdate() {
    this.paintAnswers()
  }

  componentWillUnmount = () => {
    this.context.activeStep = this.state.activeStep
  }
}

SurveyView.contextType = GeneralContext
SurveyView.propTypes = {
  history: PropTypes.object,
  classes: PropTypes.object,
  width: PropTypes.string,
}

export default withWidth()(withStyles(styles)(SurveyView))
