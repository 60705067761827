import React from 'react'
import { withRouter } from 'react-router-dom'
import { Tabs, Tab } from '@material-ui/core'
import AddCompanyView from './AddCompanyView'
import ListCompanyView from './ListCompanyView'
import AdminLayout from '../../admin/AdminLayout'
import routesCompany from '../routesCompany'

class IndexCompanyView extends React.Component {
  state = {
    value: 0,
    filters: false,
  }

  handleChange = (event, value) => {
    this.setState({ value });
  }

  redirect = route => {
    this.props.history.push(route)
  }

  render(){
    const { value, filters } = this.state
    return(
      <AdminLayout title="Empresas" subtitle="Bienvenido a Termómetro by INNSPIRAL" logo={this.context.companyLogo}>
        <Tabs
          value={this.state.value}
          indicatorColor="secondary"
          onChange={this.handleChange}
          className="tabs"
        >
          <Tab value={0} label="Ver empresas" />
          <Tab value={1}
            label="Agregar empresa"
            onClick={() => this.redirect(routesCompany.add)} 
          />
        </Tabs>
        {filters && <div></div>}
        {value === 0 && <ListCompanyView/>}
        {value === 1 && <AddCompanyView/>}
      </AdminLayout>
    )
  }
}

export default withRouter(IndexCompanyView)
