import React from 'react'
import PropTypes from 'prop-types'
import { InputBase, Grid, Button } from '@material-ui/core'

export default class WriteAndRead extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      value: props.value,
    }
  }

  componentWillReceiveProps(props){
    this.setState({value: props.value})
  }

  onTextChange = (event) => {
    this.setState({[event.target.name]: event.target.value})
    this.props.onChange(event)
  }

  render(){
    const { roles, name } = this.props
    const { value } = this.state
    if(roles.includes('admin'))
      return(
        <Grid container>
          <InputBase
            value={value}
            name={name}
            fullWidth
            multiline
            onChange={this.onTextChange}
            />
          <Grid container justify="flex-end">
          {roles.includes('admin')  && <Button variant="contained" color="primary" onClick={this.props.onSave}>Guardar</Button>}
          </Grid>
        </Grid>
        )
    else
      return(<p style={{paddingTop: '1em', whiteSpace: 'pre-line', fontSize: '16px'}}>{value}</p>)
  }
}

WriteAndRead.propTypes = {
  roles: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}
