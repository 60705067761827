import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Paper, Grid } from '@material-ui/core'
import { withRouter, Link } from 'react-router-dom'
import routesThermometer from '../routesThermometer'
import BrechaPorPilar from '../charts/BrechaPorPilar'
import TablaBrechaPorPilar from '../charts/TablaBrechaPorPilar'
import PracticasMayorBrechaPorPilar from '../charts/PracticasMayorBrechaPorPilar'
import BrechaJerarquicaPorPilar from '../charts/BrechaJerarquicaPorPilar'
import PracticasBrechaJerarquica from '../charts/PracticasBrechaJerarquica'
import WriteAndRead from '../../general/components/WriteAndRead'
import WriteAndDoubleRead from '../../general/components/WriteAndDoubleRead'

import { firestore } from '../../../firebase/firebase'

const styles = ({
  root: {
    padding: "15px",
    marginTop: "15px",
    marginBottom: "15px",
  },
  paper: {
    marginTop: "15px",
    padding: "5px",
  },
  descriptionContainer: {
    margin: "0px 0px 40px 0px",
  },
  analysisContainer: {
    margin: '40px 0px 70px 0px',
  }
})

class GeneralThermometer extends Component {
  constructor(props){
    super(props)
    this.state = {
      gapByPillar: '',
      gapByPillarTable: '',
      biggestGapPractices: '',
      hierarchicGapByPillar: '',
      hierarchicGapByPractice: '',
      finalConclusions: props.finalConclusions || 'El análisis aún no está listo, el consultor a cargo lo subirá pronto',
    }
  }

  componentDidMount(){
    firestore.collection('analysis').doc(this.props.thermometerId).get()
    .then((doc) => {
      if(doc.exists){
        let analysis = doc.data()
        this.setState({
          gapByPillar: analysis.gapByPillar || 'El análisis aún no está listo, el consultor a cargo lo subirá pronto',
          gapByPillarTable: analysis.gapByPillarTable || 'El análisis aún no está listo, el consultor a cargo lo subirá pronto',
          biggestGapPractices: analysis.biggestGapPractices || 'El análisis aún no está listo, el consultor a cargo lo subirá pronto',
          hierarchicGapByPillar: analysis.hierarchicGapByPillar || 'El análisis aún no está listo, el consultor a cargo lo subirá pronto',
          hierarchicGapByPractice: analysis.hierarchicGapByPractice || 'El análisis aún no está listo, el consultor a cargo lo subirá pronto',
        })
      }
      else {
        this.setState({
          gapByPillar: 'El análisis aún no está listo, el consultor a cargo lo subirá pronto',
          gapByPillarTable: 'El análisis aún no está listo, el consultor a cargo lo subirá pronto',
          biggestGapPractices: 'El análisis aún no está listo, el consultor a cargo lo subirá pronto',
          hierarchicGapByPillar: 'El análisis aún no está listo, el consultor a cargo lo subirá pronto',
          hierarchicGapByPractice: 'El análisis aún no está listo, el consultor a cargo lo subirá pronto',
        })
      }
    })
  }

  onTextChange = (event) => {
    this.setState({[event.target.name]: event.target.value})
  }

  sendData = (key) => {
    firestore.collection('analysis').doc(this.props.thermometerId).set({
      [key]: this.state[key],
    }, { merge: true })
    .then(() => {
      this.props.openAlert('success', 'Datos guardado con éxito')
    })
    .catch(() => {
      this.props.openAlert('error', 'Error al guardar los datos')
    })
  }

  sendFinalConclusions = () => {
    firestore.collection('thermometers').doc(this.props.thermometerId).set({
      finalConclusions: this.state.finalConclusions,
    }, { merge: true })
    .then(() => {
      this.props.openAlert('success', 'Conclusiones guardadas con éxito')
    })
    .catch(() => {
      this.props.openAlert('error', 'Error al guardar las conclusiones')
    })
  }

  render() {
    const { classes } = this.props
    return (
      <div className="thermometer-dashboard-container">
        <div>
          <div className="questions">
            <div className="number">01</div>
            <div className="body">
              <h2 className="blue-subtitle">Recordemos</h2>
            </div>
          </div>
          <Grid container alignItems="baseline">
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <h3 className="yellow-subtitle">¿NUEVO EN TERMÓMETRO?</h3>
            </Grid>
            <Grid item xs={7}>
              Si eres nuevo en Termómetro, haz click <Link to={routesThermometer.what}>aquí</Link> para entender de qué trata y qué mide esta plataforma
          </Grid>
          </Grid>
          <Grid container alignItems="baseline">
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <h3 className="yellow-subtitle">SISTEMATICIDAD</h3>
            </Grid>
            <Grid item xs={7}>
              Es la frecuencia y grado de formalidad con el cual se realizan ciertas prácticas dentro de la organización.
          </Grid>
          </Grid>
          <Grid container alignItems="baseline">
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <h3 className="yellow-subtitle">BRECHA I-S</h3>
            </Grid>
            <Grid item xs={7}>
              Corresponde a la diferencia del promedio obtenido entre importancia y sistematicidad.
          </Grid>
          </Grid>
          <Grid container alignItems="baseline">
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <h3 className="yellow-subtitle">BRECHA JERÁRQUICA</h3>
            </Grid>
            <Grid item xs={7}>
              Corresponde a la diferencia del promedio obtenido en sistematicidad entre los cargos de alta y baja jerarquía dentro de la organización.
          </Grid>
          </Grid>
          <Grid container alignItems="baseline">
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <h3 className="yellow-subtitle">ESCALA DE EVALUACIÓN</h3>
            </Grid>
            <Grid item xs={7}>
              Es el método utilizado para evaluar cada práctica. Cada una de ellas se evalúan entre 1 y 4 en sistematicidad e importancia, tal como se muestra en la siguiente figura
          </Grid>
          </Grid>
          <Grid container spacing={16}>
            <Grid item xs={4}></Grid>
            <Grid item xs={8}>
              <Paper classes={{ root: classes.root }}>
                <h3 className="blue-subtitle">Sistematicidad</h3>
                <hr className="blue-line" />
                <Grid container>
                  <Grid item xs={3}>
                    <p className="red-text">1</p>
                    <p className="red-text">Nunca</p>
                  </Grid>
                  <Grid item xs={3}>
                    <p className="purple-text">2</p>
                    <p className="purple-text">Rara vez</p>
                  </Grid>
                  <Grid item xs={3}>
                    <p className="cyan-text">3</p>
                    <p className="cyan-text">Ocasionalmente</p>
                  </Grid>
                  <Grid item xs={3}>
                    <p className="blue-text">4</p>
                    <p className="blue-text">Siempre</p>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={8}>
              <Paper classes={{ root: classes.root }}>
                <h3 className="blue-subtitle">Importancia</h3>
                <hr className="blue-line" />
                <Grid container justify="center">
                  <Grid item xs={3}>
                    <p className="red-text">1</p>
                    <p className="red-text">Baja</p>
                  </Grid>
                  <Grid item xs={3}>
                    <p className="purple-text">2</p>
                    <p className="purple-text">Media</p>
                  </Grid>
                  <Grid item xs={3}>
                    <p className="cyan-text">3</p>
                    <p className="cyan-text">Alta</p>
                  </Grid>
                  <Grid item xs={3}>
                    <p className="blue-text">4</p>
                    <p className="blue-text">Esencial</p>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
        <div>
          <div className="questions">
            <div className="number">02</div>
            <div className="body">
              <h2 className="blue-subtitle">Brechas I-S por pilar</h2>
            </div>
          </div>
          <Grid container alignItems="baseline" className={classes.descriptionContainer}>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <h3 className="yellow-subtitle">DESCRIPCIÓN</h3>
            </Grid>
            <Grid item xs={7}>
              Este gráfico representa los promedios obtenidos entre importancia y sistematicidad por cada pilar. Recordemos que la brecha corresponde a la diferencia entre la importancia y sistematicidad.
            </Grid>
          </Grid>
          {this.props.pillarsAverage &&
            <Paper classes={{ root: classes.paper }}>
              <BrechaPorPilar pillarsAverage={this.props.pillarsAverage} />
            </Paper>
          }
          <Grid container alignItems="baseline" className={classes.analysisContainer}>
          <Grid item xs={1}></Grid>
            <Grid item xs={3} className="margin-analysis">
              <h3 className="yellow-subtitle">ANÁLISIS</h3>
            </Grid>
            <Grid item xs={7}>
            <WriteAndRead value={this.state.gapByPillar} name="gapByPillar" onChange={this.onTextChange} onSave={() => this.sendData("gapByPillar")} roles={this.props.authUserData.roles}/>
            </Grid>
          </Grid>
        </div>
        <div>
          <div className="questions">
            <div className="number">03</div>
            <div className="body">
              <h2 className="blue-subtitle">Lista de las 5 prácticas con mayor brecha I-S</h2>
            </div>
          </div>
          <Grid container alignItems="baseline" className={classes.descriptionContainer}>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <h3 className="yellow-subtitle">DESCRIPCIÓN</h3>
            </Grid>
            <Grid item xs={7}>
              Esta tabla representa las 5 prácticas con mayor brecha entre importancia y sistematicidad de todos los pilares.
            </Grid>
          </Grid>
          {this.props.questionsArray &&
            <TablaBrechaPorPilar questionsArray={this.props.questionsArray} />
          }
          <Grid container alignItems="baseline" className={classes.analysisContainer}>
            <Grid item xs={1}></Grid>
            <Grid item xs={3} className="margin-analysis">
              <h3 className="yellow-subtitle">ANÁLISIS</h3>
            </Grid>
            <Grid item xs={7}>
            <WriteAndRead value={this.state.gapByPillarTable} name="gapByPillarTable" onChange={this.onTextChange} onSave={() => this.sendData("gapByPillarTable")} roles={this.props.authUserData.roles}/>
            </Grid>
          </Grid>
        </div>
        <div>
          <div className="questions">
            <div className="number">04</div>
            <div className="body">
              <h2 className="blue-subtitle">Dos prácticas con mayor brecha por pilar</h2>
            </div>
          </div>
          <Grid container alignItems="baseline" className={classes.descriptionContainer}>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <h3 className="yellow-subtitle">DESCRIPCIÓN</h3>
            </Grid>
            <Grid item xs={7}>
              Este gráfico representa las 2 practicas con mayor brecha entre importancia y sistematicidad por pilar.
            </Grid>
          </Grid>
          {this.props.firstRow && this.props.secondRow && this.props.pillars &&
            <Paper classes={{ root: classes.paper }}>
              <PracticasMayorBrechaPorPilar firstRow={this.props.firstRow}
                secondRow={this.props.secondRow} pillars={this.props.pillars}
              />
            </Paper>
          }
          <Grid container alignItems="baseline" className={classes.analysisContainer}>
            <Grid item xs={1}></Grid>
            <Grid item xs={3} className="margin-analysis">
              <h3 className="yellow-subtitle">ANÁLISIS</h3>
            </Grid>
            <Grid item xs={7}>
            <WriteAndRead value={this.state.biggestGapPractices} name="biggestGapPractices" onChange={this.onTextChange} onSave={() => this.sendData("biggestGapPractices")} roles={this.props.authUserData.roles}/>
            </Grid>
          </Grid>
        </div>
        <div>
          <div className="questions">
            <div className="number">05</div>
            <div className="body">
              <h2 className="blue-subtitle">Brecha jerárquica por pilar</h2>
            </div>
          </div>
          <Grid container alignItems="baseline" className={classes.descriptionContainer}>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <h3 className="yellow-subtitle">DESCRIPCIÓN</h3>
            </Grid>
            <Grid item xs={7}>
              Este gráfico representa la diferencia de los promedios de sistematicidad entre la alta y baja jerarquía. Es posible obtener una visión general sobre las distintas opiniones que ocurren entre jerarquías por cada pilar.
            </Grid>
          </Grid>
          {this.props.pillarsAverageHierarchy &&
            <Paper classes={{ root: classes.paper }}>
              <BrechaJerarquicaPorPilar
                pillarsAverageHierarchy={this.props.pillarsAverageHierarchy}
              />
            </Paper>
          }
          <Grid container alignItems="baseline" className={classes.analysisContainer}>
            <Grid item xs={1}></Grid>
            <Grid item xs={3} className="margin-analysis">
              <h3 className="yellow-subtitle">ANÁLISIS</h3>
            </Grid>
            <Grid item xs={7}>
            <WriteAndRead value={this.state.hierarchicGapByPillar} name="hierarchicGapByPillar" onChange={this.onTextChange} onSave={() => this.sendData("hierarchicGapByPillar")} roles={this.props.authUserData.roles}/>
            </Grid>
          </Grid>
        </div>
        <div>
          <div className="questions">
            <div className="number">06</div>
            <div className="body">
              <h2 className="blue-subtitle">Prácticas con mayor brecha jerárquica</h2>
            </div>
          </div>
          <Grid container alignItems="baseline" className={classes.descriptionContainer}>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <h3 className="yellow-subtitle">DESCRIPCIÓN</h3>
            </Grid>
            <Grid item xs={7}>
              Este gráfico señala las prácticas que obtuvieron una mayor brecha de sistematicidad entre jerarquías.
            </Grid>
          </Grid>
          {this.props.topSixQuestionsGap && this.props.questions &&
            <Paper classes={{ root: classes.paper }}>
              <PracticasBrechaJerarquica questionsArrayHierarchy={this.props.topSixQuestionsGap} questions={this.props.questions} />
            </Paper>
          }
          <Grid container alignItems="baseline" className={classes.analysisContainer}>
            <Grid item xs={1}></Grid>
            <Grid item xs={3} className="margin-analysis">
              <h3 className="yellow-subtitle">ANÁLISIS</h3>
            </Grid>
            <Grid item xs={7}>
            <WriteAndRead value={this.state.hierarchicGapByPractice} name="hierarchicGapByPractice" onChange={this.onTextChange} onSave={() => this.sendData("hierarchicGapByPractice")} roles={this.props.authUserData.roles}/>
            </Grid>
          </Grid>
        </div>
        <div>
          <div className="questions">
            <div className="number">07</div>
            <div className="body">
              <h2 className="blue-subtitle">Conclusiones finales</h2>
            </div>
          </div>
          <WriteAndDoubleRead value={this.state.finalConclusions} name="finalConclusions" onChange={this.onTextChange} onSave={this.sendFinalConclusions} roles={this.props.authUserData.roles}/>
        </div>
      </div>
    )
  }
}

GeneralThermometer.propTypes = {
  classes: PropTypes.object.isRequired,
  pillarsAverage: PropTypes.object,
  questionsArray: PropTypes.array,
  firstRow: PropTypes.array,
  secondRow: PropTypes.array,
  pillars: PropTypes.array,
  pillarsAverageHierarchy: PropTypes.object,
  topSixQuestionsGap: PropTypes.array,
  questions: PropTypes.object,
  authUserData: PropTypes.object.isRequired,
  openAlert: PropTypes.func.isRequired,
  thermometerId: PropTypes.string.isRequired,
  finalConclusions: PropTypes.string,
}

export default withRouter(withStyles(styles)(GeneralThermometer))
