import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


class PracticasBrechaJerarquica extends Component {

  constructor(props) {
    super(props)
    const data = []
    const high = []
    const low = []
    const categories = []
    const cloneQuestionArray = [...props.questionsArrayHierarchy]
    const colors = {
      "Proceso de innovación": "#1B2642",
      "Estrategia y liderazgo": "#1489A7",
      "Organización": "#FCB563",
      "Recursos": "#916953",
      "Cultura": "#157F1F"
    }
    cloneQuestionArray.forEach(question => {
      const practiceName = props.questions[question.questionId].short
      categories.push(practiceName)
      high.push(parseFloat(question.averageSystematicityAlta.toFixed(2)))
      low.push(parseFloat(question.averageSystematicityBaja.toFixed(2)))
      let q1 = 0
      let q3 = 0
      if(question.averageSystematicityAlta > question.averageSystematicityBaja) {
        q1 = question.averageSystematicityBaja
        q3 = question.averageSystematicityAlta
      } else {
        q1 = question.averageSystematicityAlta
        q3 = question.averageSystematicityBaja
      }
      data.push({
        q1,
        low: q1,
        median: null,
        q3,
        high: q3,
        name: practiceName,
        gap: question.gap.toFixed(2),
        color: colors[question.pillar],
        fillColor: colors[question.pillar],
      })
    })

    this.state = {
      optionsChart: {
        chart: {
          type: 'boxplot',
          inverted: true,
          style: {
            fontFamily: 'Raleway'
          }
        },
        title: {
          text: ""
        },
        legend: {
          enabled: true
        },
        xAxis: {
          categories,
          title: {
            text: 'PRÁCTICAS'
          }
        },
        yAxis: {
          title: {
            text: 'VALORES SISTEMATICIDAD'
          },
          min: 0,
          max: 4
        },
        plotOptions: {
          series: {
            colorByPoint: true,
            pointWidth: 18
          }
        },
        series: [{
          name: 'Brecha',
          data: data,
          tooltip: {
            useHTML: true,
            headerFormat: '<strong>{point.key}</strong><br />',
            pointFormat: "Brecha: {point.gap}",
          }
        }, {
          name: 'Alta jerarquía',
          color: Highcharts.getOptions().colors[0],
          type: 'scatter',
          data: high,
          marker: {
            symbol: "square",
            fillColor: '#FC65CF',
            lineWidth: 5,
            lineColor: '#FC65CF'
          },
          tooltip: {
            useHTML: true,
            headerFormat: '<strong>Alta Jerarquía</strong><br />',
            pointFormat: 'Sistematicidad: {point.y}'
          }
        }, {
          name: 'Baja jerarquía',
          color: Highcharts.getOptions().colors[0],
          type: 'scatter',
          data: low,
          marker: {
            symbol: "square",
            fillColor: '#0ED83C',
            lineWidth: 5,
            lineColor: '#0ED83C'
          },
          tooltip: {
            useHTML: true,
            headerFormat: '<strong>Baja Jerarquía</strong><br />',
            pointFormat: 'Sistematicidad: {point.y}'
          }
        }]
      },
    }
  }

  render() {
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={this.state.optionsChart}
      />
    )
  }
}

PracticasBrechaJerarquica.propTypes = {
  questionsArrayHierarchy: PropTypes.array.isRequired,
  questions: PropTypes.object.isRequired,
}

export default PracticasBrechaJerarquica
