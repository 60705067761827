import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Redirect } from 'react-router-dom'

import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import withWidth from '@material-ui/core/withWidth'
import { withStyles } from '@material-ui/core/styles'

import GeneralContext from '../../general/GeneralContext'
import routes from '../routesSurvey'
import { firestore } from '../../../firebase/firebase'
import SurveyLayout from '../layouts/SurveyLayout'


const stepIcon = "/images/stepperIcon.png";
// StepIconProps={{ classes: { root: classes.stepIcon, completed: classes.stepIcon } }}
const styles = () => ({
  stepper: {
    backgroundColor: "transparent"
  },
  stepIcon: {
    color: "#FCB563",
    fill: "#FCB563"
  },
  stepLabel: {
    color: "#FCB563",
  },
  completed: {
    color: "#FCB563",
  }
})

const StyledStepLabel = withStyles({
  label: {
    color: "#FCB563",
    fill: "#FCB563",
    '&$active': {
      color: "#FCB563",
      fill: "#FCB563",
    },
    '&$completed': {
      color: "#FCB563",
      fill: "#FCB563",
    }
  },
  completed: {},
  active: {}
})(StepLabel)

class Comments extends Component {

  /**
   * Agregar respuestas de empleado.
  */
  handleSend = async () => {
    const employeeRef = firestore.collection('employees')
      .doc(this.context.employeeId)
    employeeRef.set({
      answers: this.context.answers,
      comments: document.getElementById('comments').value
    }, { merge: true })
    this.props.history.push(routes.thanks)
    /**
     * chartsParticipation.
     */
    if (!this.context.employeeCompleted) {
      let chartsParticipationSnapshot
      try {
        chartsParticipationSnapshot = await firestore
          .collection('chartsParticipation')
          .where('thermometerId', '==', this.context.employee.thermometerId).get()
      } catch (error) {
        await this.context.setContext('showNotification', true)
        await this.context.setContext('typeNotification', 'error')
        await this.context.setContext(
          'messageNotification',
          'Hubo un error, por favor inténtalo más tarde.'
        )
        return
      }
      const chartsParticipation = chartsParticipationSnapshot.docs[0].data()
      let value = chartsParticipation.totalCompleted
      /* Completed totalCompleted */
      chartsParticipation.totalCompleted = 1 + value
      value = chartsParticipation
        .hierarchies[this.context.employee.hierarchy]
        .totalCompleted
      /* Completed hierarchies */
      chartsParticipation
        .hierarchies[this.context.employee.hierarchy]
        .totalCompleted = 1 + value
      value = chartsParticipation
        .areasLevels[this.context.employee.area + '-' + this.context.employee.level]
        .totalCompleted
      /* Completed areasLevels */
      chartsParticipation
        .areasLevels[this.context.employee.area + '-' + this.context.employee.level]
        .totalCompleted = 1 + value
      /* Update chartsParticipation */
      try {
        await firestore.collection('chartsParticipation')
          .doc(chartsParticipationSnapshot.docs[0].id)
          .update(chartsParticipation)
      } catch (error) {
        await this.context.setContext('showNotification', true)
        await this.context.setContext('typeNotification', 'error')
        await this.context.setContext(
          'messageNotification',
          'Hubo un error, por favor inténtalo más tarde.'
        )
        return
      }
    }
  }

  showThermometer = index => {
    if (index === 6) {
      return <img style={{ height: "70px", marginTop: "-37px", marginBottom: "-10px" }} src={stepIcon} alt={stepIcon} />
    } else {
      return index + 1;
    }
  }

  render() {
    if (this.context.employeeId === '') {
      return <Redirect to={routes.home} />
    }
    const steps = [
      'Antes de partir',
      'Proceso de innovación',
      'Estrategia y liderazgo',
      'Organización',
      'Recursos',
      'Cultura',
      'Comentarios',
      '¡Gracias!'
    ]
    const { classes } = this.props

    return (
      <SurveyLayout>
        <Stepper
          id="stepper"
          alternativeLabel
          activeStep={6}
          style={{ marginTop: "30px", marginBottom: "60px" }}
          classes={{ root: classes.stepper }}
        >
          {steps.map((label, index) => {
            const props = {};
            const labelProps = {};
            return (
              <Step key={label} {...props}>
                <StyledStepLabel icon={this.showThermometer(index)} {...labelProps}
                  StepIconProps={{ classes: { root: classes.stepIcon, text: classes.stepIcon } }}>
                  {this.props.width === 'xs' ? '' : label}
                </StyledStepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div className="content">
          <h1 style={{ marginBottom: "50px" }} align="center">
            Comentarios
          </h1>
          <p style={{ marginBottom: "30px" }}>
            Escribe libremente comentarios sobre prácticas, instancias, ideas
            u opiniones que consideres importantes y te gustaría que formaran
            parte de tu empresa.
          </p>
          <Input
            className="input-home"
            id='comments'
            disableUnderline={true}
            multiline
            style={{ width: "100%" }}
            rows="10"
          />
        </div>
        <div className="footer">
          <Button
            className="btn-primary"
            variant="contained"
            onClick={() => this.props.history.push(routes.survey)}
          >
            Volver
          </Button>
          <Button
            className="btn-primary"
            variant="contained"
            onClick={this.handleSend}
          >
            Enviar
          </Button>
        </div>
      </SurveyLayout>
    )
  }

}

Comments.contextType = GeneralContext
Comments.propTypes = {
  history: PropTypes.object,
  classes: PropTypes.object,
  width: PropTypes.string
}

export default withWidth()(withStyles(styles)(Comments))
