import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'

import routesUser from '../../user/routesUser'


class Header extends Component {
  render() {
    let header = "header-with-logo"
    if (this.props.history.location.pathname === "/") {
      header = "header-without-logo"
    } else {
      header = "header-with-logo"
    }

    return (
      <div className="header">
        <div className={header}>
          <img
            src="/images/logo_termometro-innspiral.png"
            alt="Logo Termómetro con Innspiral"
          />
          <Link to={routesUser.login}>INICIAR SESIÓN</Link>
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Header);
