import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Redirect } from 'react-router-dom'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'
import withWidth from '@material-ui/core/withWidth'

import GeneralContext from '../../general/GeneralContext'
import SurveyLayout from '../layouts/SurveyLayout'
import routes from '../routesSurvey'


class Before extends Component {

  handleSelect = (e) => {
    /**
     * Get values.
     */
    const elementId = e.target.id.split("-")
    const questionId = elementId[0]
    const valuationType = elementId[1]
    /**
     * Paint one element.
     */
    const valuations = ['never', 'rarely', 'occasionally', 'always']
    for (let v of valuations) {
      const element = document.getElementById(`${questionId}-${valuationType}-${v}`)
      element.style.backgroundColor = "#FFFFFF"
      element.style.color = "#000000"
    }
    e.target.style.backgroundColor = "#FA6163"
    e.target.style.color = "#FFFFFF"
  }

  render() {
    if (this.context.employeeId === '') {
      return <Redirect to={routes.home} />
    }
    let answer = "answer"
    answer += this.props.width === "xs" ? " answer--xs" : ""
    const spacing = this.props.width === "xs" ? 8 : 16

    return (
      <SurveyLayout>
        <div className="content">
          <h1 align="center">Antes de partir,</h1>
          <Grid container spacing={40}>
            <Grid item xs={12} sm={12} md={4}>
              <h2 align="center">Innovación</h2>
              <p align="center">
                Entendemos innovación como nuevas formas de hacer las cosas que,
                utilizando el conocimiento disponible generan valor adicional para
                la compañía.
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <h2 align="center">Sistematicidad</h2>
              <p align="center">
                Es la frecuencia y grado de formalidad con el cuál se realizan
                ciertas prácticas dentro de la organización, es decir, qué tan
                sistemático es dentro de la compañía.
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <h2 align="center">Importancia</h2>
              <p align="center">
                Qué importancia le asignas a esta práctica dentro de la
                organización (cómo consideras que debiese ser).
              </p>
            </Grid>
            <Grid item xs={12}>
              <h2 align="center" className="mt-3">Tipos de preguntas</h2>
            </Grid>
          </Grid>
          <p className="mb-1">
            Responda las siguientes preguntas, teniendo como referencia la
            escala que se detalla a continuación:
          </p>
          <div className="mt-1">
            <strong>Sistematicidad</strong>
          </div>
          <Grid className="mt-1" container spacing={spacing} align="center">
            <Grid item xs={12} sm={6} md={3}>
              <div
                id="123-systematicity-never"
                className={answer}
                onClick={this.handleSelect}
              >
                Nunca
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div
                id="123-systematicity-rarely"
                className={answer}
                onClick={this.handleSelect}
                style={{ backgroundColor: '#FA6163', color: '#FFFFFF'}}
              >
                Rara vez
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div
                id="123-systematicity-occasionally"
                className={answer}
                onClick={this.handleSelect}
              >
                Ocasionalmente
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div
                id="123-systematicity-always"
                className={answer}
                onClick={this.handleSelect}
              >
                Siempre
              </div>
            </Grid>
          </Grid>
          <div className="mt-1">
            <strong>Importancia</strong>
          </div>
          <Grid container spacing={spacing} align="center">
            <Grid item xs={12} sm={6} md={3}>
              <div
                id="123-importance-never"
                className={answer}
                onClick={this.handleSelect}
              >
                Baja
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div
                id="123-importance-rarely"
                className={answer}
                onClick={this.handleSelect}
              >
                Media
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div
                id="123-importance-occasionally"
                className={answer}
                onClick={this.handleSelect}
              >
                Alta
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div
                id="123-importance-always"
                className={answer}
                onClick={this.handleSelect}
                style={{ backgroundColor: '#FA6163', color: '#FFFFFF' }}
              >
                Esencial
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="footer">
          <Button
            className="btn-primary"
            variant="contained"
            onClick={() => this.props.history.push(routes.home)}
          >
            Volver
          </Button>
          <Button
            className="btn-primary"
            variant="contained"
            onClick={() => this.props.history.push(routes.survey)}
          >
            Continuar
          </Button>
        </div>
      </SurveyLayout>
    );
  }
}

Before.contextType = GeneralContext
Before.propTypes = {
  history: PropTypes.object,
  width: PropTypes.string,
}

export default withWidth()(Before)
