import React, { Component } from 'react'
import PropTypes from 'prop-types'

import HeaderSurveyLayout from './HeaderSurveyLayout'


class SurveyLayout extends Component {
  render() {
    return (
      <div className="body-survey">
        <div className="container">
          <HeaderSurveyLayout />
          {this.props.children}
        </div>
      </div>
    )
  }
}

SurveyLayout.propTypes = {
  history: PropTypes.object,
  width: PropTypes.string,
  children: PropTypes.array,
}

export default SurveyLayout
