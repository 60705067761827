import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
// import IconButton from '@material-ui/core/IconButton'
// import MenuIcon from '@material-ui/icons/Menu'
// import AccountCircle from '@material-ui/icons/AccountCircle'
// import MenuItem from '@material-ui/core/MenuItem'
// import Menu from '@material-ui/core/Menu'
import Drawer from '@material-ui/core/Drawer'
import { loadCSS } from 'fg-loadcss/src/loadCSS'
// import Icon from '@material-ui/core/Icon'
import withWidth from '@material-ui/core/withWidth'

import routesUser from '../user/routesUser'

import GeneralContext from '../general/GeneralContext'
import Header from '../general/components/Header';
import AdminRole from './sidebars/AdminRole'
import AdminRoleWithCompany from './sidebars/AdminRoleWithCompany'
import ViewerRole from './sidebars/ViewerRole'

import { auth, firestore } from '../../firebase/firebase'


const drawerWidth = 240

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#2A3751",
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 17px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    backgroundColor: '#FCFCFC',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  grow: {
    marginLeft: '25px',
    flexGrow: 1,
  },
})

class AdminLayout extends Component {

  constructor(props) {
    super(props)
    this.state = {
      drawerOpen: true,
      anchorEl: null,
      loading: true
    }
  }

  componentDidMount = async () => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.7.0/css/all.css',
      // document.querySelector('#insertion-point-jss'),
    )
    if (this.props.width === 'xs') {
      this.setState({ drawerOpen: false })
    }
    let companyDoc = await firestore.collection('companies')
    .where('slug', '==', this.props.match.params.company).get()
    let company = companyDoc.docs[0].data()
    this.setState({nameCompany: company.name, nameCompanySlug: company.slug, companyId: companyDoc.id, loading: false})
  }

  logout = () => {
    auth.signOut().then(() => {
      this.props.history.push(routesUser.login)
    })
  }

  handleDrawerOpen = () => {
    // this.setState({ drawerOpen: true })
    this.setState({ drawerOpen: !this.state.drawerOpen })
  }

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false })
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  getSidebar = () => {
    let roles = this.context.authUserData.roles
    if(roles.includes('viewer'))
      return <ViewerRole history={this.props.history} nameCompany={this.state.nameCompany} nameCompanySlug={this.state.nameCompanySlug} logout={this.logout}/>
    if(roles.includes('admin') && this.state.nameCompany !== '')
      return <AdminRoleWithCompany history={this.props.history} nameCompany={this.state.nameCompany} nameCompanySlug={this.state.nameCompanySlug} logout={this.logout}/>
    if(roles.includes('admin'))
      return <AdminRole history={this.props.history} nameCompany={this.state.nameCompany} nameCompanySlug={this.state.nameCompanySlug} logout={this.logout}/>
  }

  render() {
    if (this.state.loading)
      return null
    const { classes, title, subtitle, logo, status } = this.props
    let { drawerOpen } = this.state
    return (
      <div className={classes.root}>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          style={{ backgroundColor: '#2A3751' }}
        >
          <div className={classes.drawerHeader}>
            <img
              src="/images/logo_termometro-innspiral.png"
              height="80px"
              alt="Logo termómetro"
            />
          </div>
          {this.getSidebar()}
        </Drawer>
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawerOpen,
          })}
        >
          <Header className="header-paper" title={title} subtitle={subtitle} logo={logo} KPIs={this.props.KPIs} status={status} />
          <div className="body-admin">
            {this.props.children}
          </div>
        </main>
      </div>
    )
  }

}

AdminLayout.contextType = GeneralContext
AdminLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  children: PropTypes.array,
  history: PropTypes.object,
  match: PropTypes.object,
  width: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.bool,
  logo: PropTypes.string,
  KPIs: PropTypes.object,
}

const AuxComponent = Object.assign(withRouter(AdminLayout), { contextType: undefined })
const Aux2Component = withStyles(styles, { withTheme: true })(AuxComponent)
export default withWidth()(Aux2Component)
