import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter, generatePath } from 'react-router-dom'
import compareDesc from 'date-fns/compare_desc'

// import excelToJson from 'convert-excel-to-json'
// import XLSX from 'xlsx-style'
import XLSX from 'xlsx'

import { Grid, InputLabel, Button, Hidden, TextField, MenuItem, Paper } from '@material-ui/core'
import ArchiveIcon from '@material-ui/icons/Archive'
import GeneralContext from '../../general/GeneralContext'
import AdminLayout from '../../admin/AdminLayout'
import DatePicker from '../../../styling/DatePicker'
import { firestore } from '../../../firebase/firebase'
import routesThermometer from '../routesThermometer'

class AddThermometerView extends Component {

  constructor(props) {
    super(props)
    this.state = {
      startDate: null,
      endingDate: null,
      fileCSV: null,
      users: [],
      companies: [],
      company: {},
      selectedCompanyName: 'Empresa',
      totalEmployees: 0,
      totalHighHierarchy: 0,
    }
  }

  componentDidMount = async () => {
    /**
     * Update this.context.nameCompanySlug
     */
    const nameCompanySlug = this.props.history.location.pathname.split('/')[3]
    await this.context.setContext('nameCompanySlug', nameCompanySlug)
    /*
    * Update this.context.nameCompany
    */
    let currentCompany;
    let companies = []
    try {
      let companiesSnapshot = await firestore.collection('companies').orderBy('name').get()
      companiesSnapshot.forEach((doc) => companies.push({ ...doc.data(), id: doc.id }))
      currentCompany = companies.find((doc) => doc.slug === this.context.nameCompanySlug)
    } catch (error) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
      return
    }

    await this.context.setContext('nameCompany', currentCompany.name)
    await this.context.setContext('companyId', currentCompany.id)
    this.setState({ companies: companies, company: currentCompany, selectedCompanyName: currentCompany.name })
  }

  handleChangeCompany = (event) => {
    this.setState({ selectedCompanyName: event.target.value })
  }

  handleChangeStartDate = date => {
    this.setState({ startDate: date })
  }

  handleChangeEndingDate = date => {
    this.setState({ endingDate: date })
  }

  handleChangeFileCSV = async (event) => {
    let file = event.target.files[0]
    if (!file)
      return
    /**
     * Validate XLSX
     */
    const reader = new FileReader()
    reader.onload = async () => {
      const workbook = await XLSX.read(reader.result, { type: 'binary' })
      const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const data = await XLSX.utils.sheet_to_json(first_worksheet, { header: 1 })
      let totalRows = data.length - 1
      for (let i = 1; i < totalRows; i++) {
        for (let j = 0; j < data[i].length - 1; j++) {
          if (!data[i][j]) {
            await this.context.setContext('showNotification', true)
            await this.context.setContext('typeNotification', 'error')
            await this.context.setContext('messageNotification', 'Faltan campos en el excel')
            return
          }
        }
      }
      this.setState({ fileCSV: file })
      return
    }
    await reader.readAsBinaryString(file)
  }

  handleTextChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  addThermometer = async () => {

    /**
     * Validate fields.
     */
    if (this.state.startDate === null ||
      this.state.endingDate === null ||
      this.state.fileCSV === null ||
      this.state.totalEmployees === 0 ||
      this.state.totalHighHierarchy === 0) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Por favor, complete todos los campos.'
      )
      return
    }

    this.context.setContext('showNotification', true)
    this.context.setContext('typeNotification', 'info')
    this.context.setContext('messageNotification', 'Estamos creando el termómetro...')

    const orden = compareDesc(this.state.startDate, this.state.endingDate)
    if (orden < 1) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'La fecha de cierre debe ser mayor a la fecha de inicio.'
      )
      return
    }
    /*
     * Create thermometer.
     */
    let thermometer
    try {
      thermometer = await firestore.collection('thermometers').add({
        active: true,
        companyId: this.context.companyId,
        startDate: this.state.startDate,
        endingDate: this.state.endingDate,
        totalEmployees: this.state.totalEmployees,
        totalHighHierarchy: this.state.totalHighHierarchy
      })
    } catch (error) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
      return
    }

    /**
     * Extract users from XLSX
     */
    const users = await this.extractUsersFromXLSX(thermometer.id)

    if (!users) {
      return
    }

    /**
     * Active company.
     */
    try {
      await firestore.collection('companies')
        .doc(this.context.companyId)
        .update({
          'active': true,
        })
    } catch (error) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
      return
    }

    /**
     * Create employees.
     */
    for (let i = users.length - 1; i > -1; --i) {
      const user = users[i]
      // user.answers = answers
      try {
        await firestore.collection('employees').add(user)
      } catch (error) {
        await this.context.setContext('showNotification', true)
        await this.context.setContext('typeNotification', 'error')
        await this.context.setContext(
          'messageNotification',
          'Hubo un error, por favor inténtalo más tarde.'
        )
        return
      }
    }
    /**
     * infoCharts.
     */
    const infoCharts = this.getInfoCharts(users)
    /**
     * Create chartsParticipation.
     */
    try {
      await firestore.collection('chartsParticipation').add({
        totalCompleted: 0,
        totalEmployees: users.length,
        hierarchies: infoCharts.hierarchies,
        areasLevels: infoCharts.areasLevels,
        thermometerId: thermometer.id,
      })
    } catch (error) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
      return
    }
    /**
     * Create chartsGeneral.
     */
    try {
      await firestore.collection('chartsGeneral').add({
        thermometerId: thermometer.id,
      })
    } catch (error) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
      return
    }
    /**
     * Create chartsPillars.
     */
    try {
      await firestore.collection('chartsPillars').add({
        thermometerId: thermometer.id,
      })
    } catch (error) {
      await this.context.setContext('showNotification', true)
      await this.context.setContext('typeNotification', 'error')
      await this.context.setContext(
        'messageNotification',
        'Hubo un error, por favor inténtalo más tarde.'
      )
      return
    }
    /**
     * Notification of success.
     */
    await this.context.setContext('showNotification', true)
    await this.context.setContext('typeNotification', 'success')
    await this.context.setContext(
      'messageNotification',
      'Termómetro creado con éxito.'
    )
    /*
     * Redireccionar.
     */
    this.props.history.push(generatePath(routesThermometer.detail, {
      company: this.context.nameCompanySlug,
      thermometer: thermometer.id
    }))
  }

  extractUsersFromXLSX = async (thermometerId) => {
    /**
     * Cargar el csv, dividir las columnas por (";") y obtener el
     * rut, area, posición, jerarquía y nivel del empleado.
     */
    let users = []
    const reader = new FileReader()
    reader.onload = async () => {
      const workbook = await XLSX.read(reader.result, { type: 'binary' })
      const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const data = await XLSX.utils.sheet_to_json(first_worksheet, { header: 1 })
      let totalRows = data.length - 1
      for (let i = 1; i < totalRows; i++) {
        const aux = {
          email: data[i][0].toLowerCase(),
          area: data[i][1].toLowerCase(),
          level: data[i][2].toLowerCase(),
          hierarchy: data[i][3].toLowerCase(),
          thermometerId,
        }
        await users.push(aux)
      }
    }
    await reader.readAsBinaryString(this.state.fileCSV)
    return users
  }

  getInfoCharts = (users) => {
    const hierarchies = {}
    const areasLevels = {}
    for (let i = 0, len = users.length; i < len; ++i) {
      const user = users[i]
      if (areasLevels[`${user.area}-${user.level}`]) {
        areasLevels[`${user.area}-${user.level}`].totalEmployees += 1
      } else {
        areasLevels[`${user.area}-${user.level}`] = {
          totalCompleted: 0,
          totalEmployees: 1,
        }
      }
      if (hierarchies[user.hierarchy]) {
        hierarchies[user.hierarchy].totalEmployees += 1
      } else {
        hierarchies[user.hierarchy] = {
          totalCompleted: 0,
          totalEmployees: 1,
        }
      }
    }
    return { hierarchies, areasLevels }
  }

  render() {
    return (
      <AdminLayout title={this.context.nameCompany} subtitle="Termómetros">
        <h1 className="h1-admin">
          Nuevo termómetro
        </h1>
        <Paper style={{ padding: '2em' }}>
          <Grid container className="form-admin">
            <Hidden smDown>
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                <img
                  src="/images/logo-termometro.png"
                  height="430px"
                  alt="Logo Termómetro"
                />
              </Grid>
            </Hidden>
            <Grid item sm={12} md={8}>
              <Grid container className="form-admin">
                <Grid item xs={12}>
                  <div className="form-control">
                    <TextField
                      id="company-select"
                      value={this.state.selectedCompanyName}
                      onChange={this.handleChangeCompany}
                      className="select"
                      fullWidth
                      select
                      variant="outlined"
                      label="Empresa"
                      inputProps={{
                        className: 'custom-input'
                      }}
                      style={{ width: '17em' }}
                    >
                      {this.state.companies.map((company) => <MenuItem key={company.id} value={company.name}>{company.name}</MenuItem>)}
                    </TextField>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="form-control">
                    <TextField
                      variant="outlined"
                      type="number"
                      value={this.state.totalEmployees}
                      name="totalEmployees"
                      onChange={this.handleTextChange}
                      label="Cantidad de empleados"
                      inputProps={{
                        className: 'custom-input'
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="form-control">
                    <TextField
                      variant="outlined"
                      type="number"
                      value={this.state.totalHighHierarchy}
                      name="totalHighHierarchy"
                      onChange={this.handleTextChange}
                      label="Empleados de alta jerarquía"
                      inputProps={{
                        className: 'custom-input'
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="form-control">
                    <InputLabel className="title" htmlFor="name-company" shrink>
                      Fecha inicio
                    </InputLabel>
                    <DatePicker
                      onChange={this.handleChangeStartDate}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="form-control">
                    <InputLabel className="title" htmlFor="name-company" shrink>
                      Fecha cierre
                    </InputLabel>
                    <DatePicker
                      onChange={this.handleChangeEndingDate}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="form-control csv">
                    <InputLabel className="title" htmlFor="component-simple" shrink style={{ marginBottom: 0 }}>
                      Subir excel formato empresa
                    </InputLabel>
                    <a href="https://firebasestorage.googleapis.com/v0/b/termometro-inn.appspot.com/o/plantillaTermometro.xlsx?alt=media&token=7a179bca-1999-4bfc-b084-cbd388966f5a">
                      Descargar plantilla <ArchiveIcon />
                    </a>

                    <input
                      accept={['application/vnd.ms-excel',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                      ]}
                      id="company-employees-file"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={this.handleChangeFileCSV}
                    />
                    <label htmlFor="company-employees-file" style={{ width: '17em' }}>
                      <Button
                        variant="contained"
                        style={{ marginTop: '15px' }}
                        className="btn-primary"
                        component="span"
                        fullWidth
                      >
                        Seleccionar Excel
                      </Button>
                    </label>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ textAlign: 'right', marginTop: '3em' }}>
          <Button
            variant="contained"
            className="btn-primary"
            onClick={this.addThermometer}
          >
            Crear termómetro
          </Button>
        </div>
      </AdminLayout>
    )
  }
}

AddThermometerView.contextType = GeneralContext
AddThermometerView.propTypes = {
  history: PropTypes.object,
}

/* Solve problem of property contextType */
export default Object.assign(withRouter(AddThermometerView), { contextType: undefined })
