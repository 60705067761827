import { createContext } from 'react'


export default createContext()

export const surveyContextValues = {
  employeeId: '',
  employeeCompleted: false,
  employee: {},
  activeStep: 1,
  pillars: [],
  answers: {},
  comments: '',
}
